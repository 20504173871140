import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { H3, H5, H6, Paragraph } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import ResetDatabaseService from "services/ResetDatabaseService";
import notification from "helpers/notifications";
import DeleteDialog from "components/shared/DeleteDialog";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

function ResetDatabase() {
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleClickButton = () => {
    console.log("Click");
    setOpen(true);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
  };

  const handleDeleteDB = async () => {
    setLoading(true);
    console.log("reset database");
    const resp = await ResetDatabaseService.Resetdatabase();
    console.log("resp", resp);
    notification.success("ResetDatabase", resp.message);
    setOpen(false);
    setIsChecked(false);
    setLoading(false);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Box p={8}>
            <FlexBox justifyContent={"center"}>
              <H3>Reset Database</H3>
            </FlexBox>
            <FlexBox
              mt={5}
              justifyContent={"center"}
              sx={{ alignItems: "center" }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography variant="body1" style={{ fontSize: "12px" }}>
                      I understand that this action will delete the database and
                      it cannot be recovered.
                    </Typography>
                  }
                />
              </FormGroup>
            </FlexBox>

            <FlexBox mt={2} justifyContent={"center"}>
              <StyledButton
                onClick={handleClickButton}
                disabled={!isChecked}
                variant="outlined"
                color="error"
                size="small"
              >
                RESET
              </StyledButton>
            </FlexBox>
          </Box>
        </CardContent>
      </Card>
      {/* <Dialog
        open={open}
        //  onClose={handleClose}
      >
        <DialogTitle
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H5>Confirm Delete</H5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontSize: "0.925rem",
            }}
          >
            <H6> will delete this user permanently. Are you sure?</H6>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirm}>Cancel</Button>
          <Button onClick={() => handleDeleteDB()}>Delete</Button>
        </DialogActions>
      </Dialog> */}
      <DeleteDialog
        open={open}
        title={"Confirm Delete"}
        subTitle={"It will delete this user permanently. Are you sure?"}
        handleCancel={handleCancelConfirm}
        handleConfirm={() => handleDeleteDB()}
        isLoading={loading}
      />
    </div>
  );
}

export default ResetDatabase;
