import { useEffect, useState } from "react";
import { Button, Grid, styled, Select, MenuItem } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { SERVER_BASE_URL } from "../../config";
import CancelIcon from "@mui/icons-material/Cancel";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
//import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H2, H3, H6, Paragraph } from "components/Typography";
import { useFormik } from "formik";
//import toast from "react-hot-toast";
//import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import FormControl from "@mui/material/FormControl";
import { FileInput, ImageInput } from "formik-file-and-image-input/lib";
import countries from "../../data/country";
import Zones from "../../data/timezones.json";

import { openStdin } from "process";
// import { saveUsers } from "../../../redux/actions/user";
//import { connect } from "react-redux";
//import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import VAService from "services/virtualAssistantService";
import { Box, fontSize } from "@mui/system";
//import { ConstructionOutlined } from "@mui/icons-material";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const ImageContainer = styled(Box)`
  position: relative;
`;

// const CustomImageInputWrapper = ({ onClick, fileName, src }) => {
//   return (
//     <div onClick={onClick}>
//       {!src && <button onClick={onClick}>Choose Image</button>}
//       <img src={src} />
//       <p>{fileName}</p>
//     </div>
//   );
// };
const AddVaModal = ({ open, onClose, onSuccess, edit, data }, props) => {
  const [files, setFiles] = useState([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const [discordUser, setDiscordUser] = useState(null);
  const getDiscordUser = async () => {
    let resp = await VAService.getDiscordUser();
    setDiscordUser(resp);
  };

  useEffect(() => {
    getDiscordUser();
  }, []);

  const timeZoneOptions = Object.keys(Zones.zones).map((zone) => zone);
  const [isLoading, setIsLoading] = useState(false);
  // const [Role, setRole] = useState(null);
  const [error, setError] = useState("");

  const initialValues = {
    name: data && data.name ? data.name : "",
    profile_pic: data && data.profile_pic ? data.profile_pic : "",
    experience: data && data.experience ? data.experience : "",
    country: data && data.country ? data.country : "US",
    timezone: data && data.timezone ? data.timezone : "America/New_York",
    discord: data && data.discord ? data.discord : "",
  };
  const validations = {
    name: Yup.string().min(3, "Too Short").required("Name is Required!"),
    // profile_pic: Yup.mixed().required("Your Profile pic is Required"),
    experience: Yup.string().required("Experience is Required!"),
    country: Yup.string().required("Country is Required!"),
    timezone: Yup.string().required("Timezone is Required!"),
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  });
  console.log("file", files);
  const fieldValidationSchema = Yup.object().shape(validations);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    // setFieldValue,
    touched,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: fieldValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      var formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("country", values.country);
      formdata.append("timezone", values.timezone);
      formdata.append("experience", values.experience);
      formdata.append("discord", values.discord);
      formdata.append(
        "profile_pic",
        acceptedFiles[0] ? acceptedFiles[0] : JSON.stringify(values.profile_pic)
      );
      setIsLoading(true);

      let resp;
      if (edit) {
        resp = await VAService.updateVa(data._id, formdata);
      } else {
        resp = await VAService.createVa(formdata);
      }

      if (resp && resp.status === true) {
        resetForm();
        onClose();
        onSuccess();
        acceptedFiles.splice(0, acceptedFiles.length);
        setError("");
        setFiles([]);
        setDisplay(false);
      }

      setIsLoading(false);
      // } else {
      //   setError("Profile pic is Required !");
      // }
    },
  });

  const [display, setDisplay] = useState(false);

  //Delete existing profile pic
  const handleDeleteProfile = () => {
    setDisplay(true);
    // acceptedFiles.splice(0, acceptedFiles.length);
    setValues({ ...values, profile_pic: null });
    setFiles([]);
  };

  const onhandleClose = () => {
    resetForm();
    onClose();
    setDisplay(false);
    onClose();
  };
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H3 mb={2}>
        {edit
          ? "Edit Virtual Assistant (Fake)"
          : "Create Virtual Assistant (Fake)"}
      </H3>

      <form onSubmit={handleSubmit}>
        <Scrollbar
          style={{
            maxHeight: 400,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <H6 mb={1}>NAME</H6>
              <AppTextField
                fullWidth
                size="small"
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                helperText={touched.name && errors.name}
                error={Boolean(errors.name && touched.name)}
              />
            </Grid>
            {/* {errors.name && (
              <Paragraph
                sx={{
                  fontSize: "12px !important",
                  color: "red",
                  fontWeight: "350",
                }}
              >
                {errors.name}
              </Paragraph>
            )} */}

            <Grid item xs={6}>
              <H6 mb={1}>EXPERIENCE</H6>
              <AppTextField
                fullWidth
                size="small"
                name="experience"
                placeholder="Experience"
                onChange={handleChange}
                value={values.experience}
                helperText={touched.experience && errors.experience}
                error={Boolean(errors.experience && touched.experience)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: 290, padding: 0 }}>
                <H6 mb={1}>Discord</H6>

                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Select an option" }}
                  sx={{ height: 39 }}
                  onChange={handleChange}
                  name="discord"
                  value={values.discord}
                >
                  <MenuItem value="" disabled>
                    Select a discord Members
                  </MenuItem>
                  {discordUser &&
                    discordUser.map(
                      ({ discord_id, username, discriminator }) => (
                        <MenuItem key={username} value={discord_id}>
                          {`${username} #${discriminator}`}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <H6 mb={1}>Country</H6>
              <FormControl sx={{ width: 290, padding: 0 }}>
                <Select
                  sx={{ height: 39 }}
                  onChange={handleChange}
                  name="country"
                  value={values.country}
                >
                  {countries.map(({ name, code }) => (
                    <MenuItem key={name} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>TimeZone</H6>
              <FormControl sx={{ width: 290, padding: 0 }}>
                <Select
                  sx={{ height: 39 }}
                  onChange={handleChange}
                  value={values.timezone}
                  name="timezone"
                >
                  {timeZoneOptions.map((zone) => (
                    <MenuItem key={zone} value={zone}>
                      {zone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <H6 mb={1}>Profile Pic</H6>
              {data && (
                <ImageContainer
                  style={display ? { display: "none" } : { display: "block" }}
                >
                  <img
                    style={{
                      width: `40px`,
                      height: `40px`,
                    }}
                    src={`${SERVER_BASE_URL}/${data.profile_pic?.file_path}`}
                    alt="img"
                  />
                  <CancelIcon
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "5%",
                      cursor: "pointer",
                      color: "red",
                    }}
                    onClick={() => handleDeleteProfile()}
                  />
                </ImageContainer>
              )}
              {
                <Box
                  style={
                    display || !edit
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  sx={{
                    border: "2px solid",
                    borderColor: "primary.main",
                    borderRadius: "6px",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <Box
                    {...getRootProps({ className: "dropzone" })}
                    sx={{
                      padding: "20px 5px",
                      border: "1px dashed ",
                      borderRadius: "5px",

                      borderColor: "primary.main",
                    }}
                  >
                    <input
                      name="profile_pic"
                      type="file"
                      multiple
                      {...getInputProps()}
                    />

                    {files.length === 0 && (
                      <Paragraph fontSize={13}>
                        Drag 'n' drop some files here, or click to select files
                      </Paragraph>
                    )}
                    <aside className="thumbsContainer">
                      {files.length > 0 &&
                        files.map((file) => (
                          <div className="thumb" key={file.name}>
                            <div className="thumbInner">
                              <img
                                style={{
                                  height: "40px",
                                  width: "40px",
                                }}
                                src={file.preview}
                                className="imgDropZone"
                                // Revoke data uri after image is loaded
                                // onLoad={() => {
                                //   URL.revokeObjectURL(file.preview);
                                // }}
                              />
                            </div>
                          </div>
                        ))}

                      {/* {files.length < 1 && profileData?.profilePic && (
<div className="thumb" key={profileData?.profilePic}>
 <div className="thumbInner">
   <img
     src={SERVER_BASE_URL + profileData?.profilePic}
     className="imgDropZone"
   />
 </div>
</div>
)} */}
                    </aside>
                  </Box>
                </Box>
              }

              {/* {acceptedFiles.length == 0 ? (
                    <Paragraph
                      sx={{
                        fontSize: "12px !important",
                        color: "red",
                        fontWeight: "350",
                      }}
                    >
                      {error}
                    </Paragraph>
                  ) : null} */}
            </Grid>
          </Grid>
        </Scrollbar>

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onhandleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default AddVaModal;
