import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import buyerService from "services/buyer";
import AddressList from "./AddressList";
import WhiteListdAddressService from "services/WhiteListdAddressService";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

function AddressHistory() {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  //   const [tabValue, setTabValue] = useState("api");

  // const [option, setOption] = useState("active");

  const [data, setData] = useState(null);

  const getAddressHistory = async (id, page = 1, limit = 50) => {
    setisLoading(true);
    const resp = await WhiteListdAddressService.getAddressHistory(
      id,
      page,
      limit
    );
    // let sortedUser =
    //   resp &&
    //   resp.data.sort(function (a, b) {
    //     let today = new Date();
    //     let _a = a.last_active
    //       ? new Date(a.last_active)
    //       : today.setFullYear(today.getFullYear() - 1);
    //     let _b = b.last_active
    //       ? new Date(b.last_active)
    //       : today.setFullYear(today.getFullYear() - 1);

    //     return _b - _a;
    //   });
    // resp.data = sortedUser;
    setPage(page);
    setData(resp.data);
    setisLoading(false);
  };

  useEffect(() => {
    getAddressHistory(id, 1, 50);
  }, []);

  const reload = () => {
    setPage(1);
    setLimit(50);
    // setOption("active");
    // setSearchValue("");
    getAddressHistory(id, 1, 50);
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getAddressHistory(id, page, limit);
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    const totalPages = Math.ceil(filteredItem.length / newLimit);
    const newPage = Math.min(page, totalPages);

    setPage(newPage);
    setLimit(newLimit);
    getAddressHistory(id, newPage, newLimit);
  };

  return (
    <>
      <Box pt={2} pb={4}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{t("Address History")}</H5>
          </Stack>

          <StyledStack alignItems="center" direction="row" gap={2}>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  sx={{
                    "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "10px !important", // Remove padding from the Select component
                      },
                  }}
                  value={option}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <Paragraph>All</Paragraph>
                  </MenuItem>
                  <MenuItem value={"active"}>
                    {" "}
                    <Paragraph>Active</Paragraph>{" "}
                  </MenuItem>
                  <MenuItem value={"delete"}>
                    {" "}
                    <Paragraph>Deleted</Paragraph>{" "}
                  </MenuItem>
                </Select>
              </FormControl>
              <SearchInput
                // disable_border
                value={searchValue}
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
              /> */}
            <LoadingButton
              onClick={() => reload()}
              loading={isLoading}
              variant="contained"
              sx={{
                flexShrink: 0,
              }}
            >
              Reload
            </LoadingButton>
          </StyledStack>
        </FlexBetween>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          data && (
            <AddressList
              {...data}
              num_pages={Math.ceil(data.count / data.limit)}
              onPageChange={handlePageChange}
              currentPage={page}
              handleLimitChange={handleLimitChange}
              limit={limit}
              // getUserMasterList={getUserMasterList}
            />
          )
        )}
      </Box>
    </>
  );
}

export default AddressHistory;
