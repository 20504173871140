import axios from "../utils/axios";

const WhiteListdAddressService = {};

WhiteListdAddressService.getUserWhiteListdAddress = function (
  id,
  page,
  limit,
  type
) {
  return axios({
    url: `/whitelisted-address/${id}?page=${page}&limit=${limit}&type=${type}`,
    method: "GET",
  });
};

WhiteListdAddressService.deleteAddress = function (id) {
  return axios({
    url: `/whitelisted-address/${id}`,
    method: "DELETE",
  });
};

WhiteListdAddressService.getAddressHistory = function (id, page, limit) {
  return axios({
    url: `/whitelisted-address/activity/${id}?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export default WhiteListdAddressService;
