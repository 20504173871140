import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Modal,
} from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import React, { useEffect, useState } from "react";
import buyerService from "services/buyer";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CountryFlag, { getCountry } from "components/shared/CountryFlag";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { H2, H5, Small, Span } from "components/Typography";
import { formatCurrency, getCountryNameByCode } from "utils/currencies";
import Scrollbar from "components/ScrollBar";
import { isCountryCodeExists, isCountryNameExists } from "utils/utils";
import Platform from "components/shared/Platform";
import P2PLogo from "components/shared/P2PLogo";
import { Badge2 } from "../profile/blockchainTransaction/ColumnShape";
import PartnerTags from "./PartnerTag";
import platforms from "data/platforms";
import TrustBlockPartner from "../profile/Trades/TrustBlockPartner";
import { LogoMin } from "components/shared/Logo";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 750,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));
// const StyledBox = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
// }));
const TradeVolumeVal = ({ value, crypto, isLast }) => {
  return (
    <span>
      <Span marginLeft={"4px"} fontSize={13}>
        {value} {crypto}
      </Span>
      <img
        src={`/media/svg/coins/${crypto.toLowerCase()}.svg`}
        className="ms-1"
        style={{
          width: "15px",
          height: "15px",
          marginLeft: "4px",
        }}
      />
      {/* <Span marginLeft={"4px"} fontSize={13}>
        btc&nbsp;{partner?.profile.total_btc} &nbsp; eth&nbsp;
        {partner?.profile.total_eth} &nbsp; usdt&nbsp;
        {partner?.profile.total_usdt} &nbsp; usdc&nbsp;
        {partner?.profile.total_usdc}
      </Span> */}
    </span>
  );
};

const Wrapper = styled(Box)(() => ({}));

const TradePartnerDetails = ({ showConfirm, onCancel, partner_id }) => {
  console.log("partnerid", partner_id);
  const [data, setData] = useState(null);
  const [stats, setStats] = useState(null);
  const partner = data?.partner;

  const getData = async (partner) => {
    const resp = await buyerService.getPartnerDetails(partner);
    if (resp.status) {
      setData(resp.data);
      setStats(resp.data.atlantis_stats);
    }
  };

  useEffect(() => {
    if (partner_id) {
      getData(partner_id);
    }
  }, [partner_id]);

  return (
    <StyledAppModal
      open={showConfirm}
      handleClose={onCancel}
      // sx={{ minHeight: 650 }}
      className="partner-modal"
    >
      {partner ? (
        <>
          <Box position="relative" sx={{ minHeight: 700 }}>
            <Scrollbar
              style={{
                maxHeight: 700,
              }}
            >
              <FlexBox
                justifyContent={"space-between"}
                sx={{ marginBottom: "10px" }}
              >
                <Box>
                  <FlexBox>
                    <ProfileImageWithAccount
                      account={partner?.profile}
                      buyer
                      platform={partner?.platform}
                      platformSize={25}
                      pfSize={40}
                    />
                    <Box sx={{ marginLeft: "4px" }}>
                      <span>
                        <a
                          href={`https://${partner?.platform}.com/user/${partner?.username}`}
                          target="_blank"
                          rel="noreferrer"
                          className="link-style"
                        >
                          <a className=" ">
                            <span>{partner?.profile.username}</span>

                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="16.9497"
                                y="8.46448"
                                width="13"
                                height="2"
                                rx="1"
                                transform="rotate(135 16.9497 8.46448)"
                                fill="currentColor"
                              ></rect>
                              <path
                                d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </a>
                        </a>
                      </span>

                      <FlexBox
                        sx={{
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          size="xl"
                          icon={<ThumbUpIcon color="success" />}
                          label={partner.profile.feedback_positive}
                        />
                        <Chip
                          sx={{
                            marginLeft: "5px",
                          }}
                          size="xl"
                          icon={<ThumbDownIcon color="error" />}
                          label={partner.profile.feedback_negative}
                        />
                      </FlexBox>
                      <TrustBlockPartner stats={data?.stats} />
                    </Box>
                  </FlexBox>
                  <FlexBox sx={{ marginTop: "4px", marginLeft: "45px" }}>
                    <Small
                      sx={{
                        width: 120,
                        fontSize: 10,
                        borderRadius: "4px",
                        textAlign: "center",
                        padding: " 0.6rem",
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#F1FAFF"
                            : "#212E48",
                      }}
                    >
                      <H5>
                        {Number(
                          partner?.profile.total_partners
                        ).toLocaleString()}
                      </H5>
                      <div>Total Partners</div>
                    </Small>
                    <Small
                      sx={{
                        width: 120,
                        fontSize: 10,
                        borderRadius: "4px",
                        textAlign: "center",
                        padding: " 0.6rem",
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#F1FAFF"
                            : "#212E48",
                        marginLeft: "3px",
                      }}
                    >
                      <H5>
                        {" "}
                        {Number(partner?.profile.total_trades).toLocaleString()}
                      </H5>
                      <div>Total Trades</div>
                    </Small>
                    <Small
                      sx={{
                        width: 120,
                        fontSize: 10,

                        borderRadius: "4px",
                        textAlign: "center",
                        padding: " 0.6rem",
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#F1FAFF"
                            : "#212E48",
                        marginLeft: "3px",
                      }}
                    >
                      <H5>
                        {Number(partner?.profile.trusted_by).toLocaleString()}
                      </H5>
                      <div>Trusted By</div>
                    </Small>
                  </FlexBox>
                </Box>
                <Box mr={2} textAlign={"end"}>
                  {partner?.platform && (
                    <P2PLogo size={70} platform={partner?.platform} />
                  )}
                  <Box mt={-2}>
                    <Badge2
                      status={"Joined:  1 year ago"}
                      color={(theme) =>
                        theme.palette.mode === "light" ? "lightgrey" : "grey"
                      }
                      textColor={(theme) =>
                        theme.palette.mode === "dark" ? "#f5f8fa" : "#2b2b40"
                      }
                    />
                  </Box>
                </Box>
              </FlexBox>
              <Divider />

              <Wrapper>
                {/* Show partner tags */}

                <FlexBox className="d-flex" mt={3}>
                  <LogoMin width={25} />
                  <Span style={{ fontWeight: "700" }} ml={1}>
                    Venva Statistics
                  </Span>
                </FlexBox>

                <PartnerTags
                  className={"mt-0 mb-3"}
                  tags={data.tags}
                  partner={data._id}
                  showNoResult
                />

                <Box
                  mt={0.5}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      {" "}
                      Daily Trading Total:
                    </small>
                    <small style={{ marginLeft: "4px" }}>
                      {stats ? formatCurrency(stats.daily_total) : "$0"}
                    </small>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      {" "}
                      Weekly Trading Total :
                    </small>
                    <small style={{ marginLeft: "4px" }}>
                      {stats ? formatCurrency(stats?.weekly_total) : "$0"}
                    </small>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      {" "}
                      Lifetime Trading Total :
                    </small>
                    <small style={{ marginLeft: "4px" }}>
                      {stats ? formatCurrency(stats?.lifetime_total) : "$0"}
                    </small>
                  </div>
                </Box>

                <FlexBox className="d-flex" mt={1.8}>
                  <Platform
                    platform={partner?.platform}
                    className="platform-icon w-15px"
                    size={25}
                  />
                  <Span style={{ fontWeight: "700" }} ml={1}>
                    {platforms[partner?.platform].title} Statistics
                  </Span>
                </FlexBox>

                <Box
                  mt={1}
                  sx={{
                    marginBottom: "3px",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <FlexBox>
                    <CountryFlag
                      code={
                        isCountryCodeExists(
                          data?.location?.detected_location?.iso
                        )
                          ? data?.location?.detected_location?.iso
                          : partner?.profile?.verified_id_country ||
                            partner?.profile?.verified_phone_country ||
                            partner?.profile?.last_ip_country
                      }
                      width={"18px"}
                      showToolTip={false}
                    />
                    <Small ml={0.5} style={{ fontWeight: "600" }}>
                      Country:
                    </Small>
                    <Span
                      sx={{
                        marginLeft: "5px",
                        textAlign: "center",
                      }}
                      fontSize={13}
                    >
                      {isCountryNameExists(
                        data?.location?.detected_location.name
                      )
                        ? data?.location?.detected_location.name
                        : getCountryNameByCode(
                            partner?.profile?.verified_id_country ||
                              partner?.profile?.last_ip_country
                          )}
                    </Span>
                  </FlexBox>
                </Box>
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div className="verified">
                    <span
                      style={{ color: "#49ace3" }}
                      className="trade-partner-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22px"
                        height="22px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                          fill="currentColor"
                        />
                        <path
                          d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <small style={{ fontWeight: "600" }}>Verified:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile.email_verified && (
                        <Span marginLeft={"4px"} fontSize={13}>
                          <span
                            style={{ color: "#49ace3" }}
                            className="email-icon"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                fill="currentColor"
                              />
                              <path
                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span fontSize={13}>Email</span>
                        </Span>
                      )}
                      {partner?.profile.is_verified && (
                        <Span marginLeft={"4px"} fontSize={13}>
                          <span
                            style={{ color: "#49ace3" }}
                            className="id-icon"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                                fill="currentColor"
                              />
                              <rect
                                opacity="0.3"
                                x="8"
                                y="3"
                                width="8"
                                height="8"
                                rx="4"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <Span fontSize={13}>ID</Span>
                        </Span>
                      )}
                      {partner?.profile.phone_verified && (
                        <Span marginLeft={"4px"} fontSize={13}>
                          <span
                            style={{ color: "#49ace3" }}
                            className="phone-icon"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                                fill="currentColor"
                              />
                              <path
                                opacity="0.3"
                                d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <Span fontSize={13}>Phone</Span>
                        </Span>
                      )}
                      {partner?.profile.is_verified && (
                        <Span marginLeft={"4px"} fontSize={13}>
                          <span
                            style={{ color: "#49ace3" }}
                            className="address-icon"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <Span fontSize={13}>Address</Span>
                        </Span>
                      )}
                    </Span>
                  </div>
                </Box>
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>Trading Volume:</small>
                    <TradeVolumeVal
                      value={partner?.profile.total_btc}
                      crypto={"BTC"}
                    />
                    <TradeVolumeVal
                      value={partner?.profile.total_eth}
                      crypto={"ETH"}
                    />
                    <TradeVolumeVal
                      value={partner?.profile.total_usdt}
                      crypto={"USDT"}
                    />
                    <TradeVolumeVal
                      value={partner?.profile.total_usdc}
                      crypto={"USDC"}
                      isLast={true}
                    />
                  </div>
                </Box>
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>Last seen:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.last_seen
                        ? partner?.profile?.last_seen
                        : "No last Seen"}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}> Trusted :</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.is_trusted ? "True" : "False"}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      Completed trades with me :
                    </small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile.completed_trades_with_me}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}> Blocked by:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.blocked_by
                        ? partner?.profile?.blocked_by
                        : 0}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}> Blocked count:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.blocked_count
                        ? partner?.profile?.blocked_count
                        : 0}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>Vendor:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile.is_vendor ? "True" : "False"}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      Total crypto traded:
                    </small>

                    <TradeVolumeVal
                      value={partner?.profile.total_crypto_traded.btc}
                      crypto={"BTC"}
                    />
                    <TradeVolumeVal
                      value={partner?.profile.total_crypto_traded.eth}
                      crypto={"ETH"}
                    />
                    <TradeVolumeVal
                      value={partner?.profile.total_crypto_traded.usdt}
                      crypto={"USDT"}
                    />
                    <TradeVolumeVal
                      value={partner?.profile.total_crypto_traded.usdc}
                      crypto={"USDC"}
                      isLast={true}
                    />
                  </div>
                </Box>
                {/* Monthly */}

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      Monthly trade stats:
                    </small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      Average time to payment &nbsp;
                      {partner?.profile?.monthly_trade_stats
                        ?.avg_time_to_payment
                        ? partner?.profile?.monthly_trade_stats
                            ?.avg_time_to_payment
                        : 0}{" "}
                      &nbsp; Average time to release &nbsp;
                      {partner?.profile?.monthly_trade_stats
                        ?.avg_time_to_release
                        ? partner?.profile?.monthly_trade_stats
                            ?.avg_time_to_release
                        : 0}{" "}
                      &nbsp; Trade count success &nbsp;
                      {partner?.profile?.monthly_trade_stats
                        ?.trades_count_success
                        ? partner?.profile?.monthly_trade_stats
                            ?.trades_count_success
                        : 0}{" "}
                      &nbsp; Trades count total &nbsp;
                      {partner?.profile?.monthly_trade_stats?.trades_count_total
                        ? partner?.profile?.monthly_trade_stats
                            ?.trades_count_total
                        : 0}
                      &nbsp; Trade percent success &nbsp;
                      {partner?.profile?.monthly_trade_stats
                        ?.trades_percent_success
                        ? partner?.profile?.monthly_trade_stats
                            ?.trades_percent_success
                        : 0}
                      &nbsp; Trade volume total &nbsp;
                      {partner?.profile?.monthly_trade_stats
                        ?.trades_volume_total
                        ? partner?.profile?.monthly_trade_stats
                            ?.trades_volume_total
                        : 0}
                    </Span>
                  </div>
                </Box>
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}> Created At:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      <FormattedDateTime date={partner?.profile?.created_at} />
                    </Span>
                  </div>
                </Box>

                {partner?.platform === "noones" && (
                  <>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#f5f8fa"
                            : "#2b2b40",
                      }}
                      className="partner-details"
                    >
                      <div>
                        <small style={{ fontWeight: "600" }}>
                          {" "}
                          Last seen timestamp:
                        </small>
                        <Span marginLeft={"4px"} fontSize={13}>
                          {partner?.profile?.last_seen_timestamp
                            ? partner?.profile?.last_seen_timestamp
                            : "No last Seen timestamp"}
                        </Span>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#f5f8fa"
                            : "#2b2b40",
                      }}
                      className="partner-details"
                    >
                      <div>
                        <small style={{ fontWeight: "600" }}>
                          {" "}
                          Online status:
                        </small>
                        <Span marginLeft={"4px"} fontSize={13}>
                          {partner?.profile?.online_status
                            ? partner?.profile?.online_status
                            : "No online status"}
                        </Span>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#f5f8fa"
                            : "#2b2b40",
                      }}
                      className="partner-details"
                    >
                      <div>
                        <small style={{ fontWeight: "600" }}>
                          Chat status:
                        </small>
                        <Span marginLeft={"4px"} fontSize={13}>
                          {partner?.profile?.chat_status
                            ? partner?.profile?.chat_status
                            : "No chat status"}
                        </Span>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#f5f8fa"
                            : "#2b2b40",
                      }}
                      className="partner-details"
                    >
                      <div>
                        <small style={{ fontWeight: "600" }}>
                          Make as referrer:
                        </small>
                        <Span marginLeft={"4px"} fontSize={13}>
                          {partner?.profile?.make_as_referrer
                            ? "True"
                            : "False"}
                        </Span>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#f5f8fa"
                            : "#2b2b40",
                      }}
                      className="partner-details"
                    >
                      <div>
                        <small style={{ fontWeight: "600" }}>
                          Can be blocked:
                        </small>
                        <Span marginLeft={"4px"} fontSize={13}>
                          {partner?.profile?.can_be_blocked ? "True" : "False"}
                        </Span>
                      </div>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}> Status:</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.status
                        ? partner?.profile?.status
                        : "No status"}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}>
                      First trade date:
                    </small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.first_trade_date ? (
                        <FormattedDateTime
                          date={partner?.profile?.first_trade_date}
                        />
                      ) : (
                        "No first trade date"
                      )}
                    </Span>
                  </div>
                </Box>

                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#f5f8fa" : "#2b2b40",
                  }}
                  className="partner-details"
                >
                  <div>
                    <small style={{ fontWeight: "600" }}> Blocked :</small>
                    <Span marginLeft={"4px"} fontSize={13}>
                      {partner?.profile?.is_blocked ? "True" : "False"}
                    </Span>
                  </div>
                </Box>

                {partner?.platform === "noones" && (
                  <>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#f5f8fa"
                            : "#2b2b40",
                      }}
                      className="partner-details"
                    >
                      <div>
                        <small style={{ fontWeight: "600" }}>
                          Offer liquidity:
                        </small>
                        <Span marginLeft={"4px"} fontSize={13}>
                          Code {""} {partner?.profile?.offer_liquidity.code}{" "}
                          &nbsp; Value {""}{" "}
                          {partner?.profile?.offer_liquidity.value}
                        </Span>
                      </div>
                    </Box>
                    {partner?.platform === "noones" &&
                      partner?.profile?.languages[0] && (
                        <Box
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "light"
                                ? "#f5f8fa"
                                : "#2b2b40",
                          }}
                          className="partner-details"
                        >
                          <div>
                            <small style={{ fontWeight: "600" }}>
                              Languages:
                            </small>
                            <Span marginLeft={"4px"} fontSize={13}>
                              Code {""} {partner?.profile?.languages[0].code}{" "}
                              &nbsp; Value {""}{" "}
                              {partner?.profile?.languages[0].name} &nbsp;
                              Native {partner?.profile?.languages[0].native}
                            </Span>
                          </div>
                        </Box>
                      )}
                  </>
                )}
              </Wrapper>
            </Scrollbar>{" "}
          </Box>
          <FlexBox justifyContent="center" gap={2} marginTop={4}>
            <Button size="small" variant="outlined" onClick={onCancel}>
              Close
            </Button>
          </FlexBox>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      )}
      {/* </Scrollbar> */}
      {/* <Divider /> */}
    </StyledAppModal>
  );
};

export default TradePartnerDetails;
