import axios from "../utils/axios";

const ErrorLogService = {};

ErrorLogService.getErrorLog = function () {
  return axios({
    url: `/error-logs`,
    method: "GET",
  });
};
ErrorLogService.readErrorLog = function (fileName) {
  return axios({
    url: `/error-logs/read/${fileName}`,
    method: "GET",
  });
};

export default ErrorLogService;
