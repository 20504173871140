import { Box } from "@mui/material";
import { UserContextData } from "App";
import { Span } from "components/Typography";
import { SettingsContext } from "contexts/settingsContext";
import React from "react";
import { useContext } from "react";

function P2PLogo({ size, platform }) {
  const { settings } = useContext(SettingsContext);
  return (
    // <Box
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    <Span sx={{ position: "relative", top: 5 }}>
      <img
        src={`/images/${platform}-logo${
          settings.theme === "light" ? "" : "-dark"
        }.svg`}
        style={{
          height: `${size}px`,
          width: `${size}px`,
        }}
      />
    </Span>

    // </Box>
  );
}

P2PLogo.defaultProps = {
  platform: "noones",
};

export default P2PLogo;
