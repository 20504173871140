import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import CustomChip from "components/shared/CustomChip";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import ATooltip from "components/shared/ATooltip";
import CheckmarkCircle from "icons/CheckmarkCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag, { getCountry } from "components/shared/CountryFlag";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import DeleteButton from "page-sections/profiles/profile/DeleteButton";
import EditButton from "page-sections/profiles/profile/EditButton";
import PasswordButton from "page-sections/profiles/profile/PasswordButton";
import RoleBadge from "utils/RoleBadge";
import LockButton from "page-sections/profiles/profile/overview/LockButton";
import DiscordAvatar from "components/avatars/DiscordAvatar";
import GoogleIcon from "icons/GoogleIcon";
import SignInMethod from "components/shared/SignInMethod";
import notification from "helpers/notifications";
import PersonOffButton from "page-sections/profiles/profile/SessionButton";
import { formatCurrency, getCurrencyNameByCode } from "utils/currencies";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { useState } from "react";
import TradePartnerDetails from "page-sections/profiles/buyer/TradePartnerDetails";
import { isCountryCodeExists, isCountryNameExists } from "utils/utils";
import cryptoAssets from "data/supportedAssets";
import CryptoImg from "components/shared/CryptoImg";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)`
      padding: 4,
      minWidth: 60,
      marginRight: 3,
    `;

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

// anchorEl,
// handleClick,
// _handleClose,
// _open,
// id
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

const AddressColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: " DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Box minWidth={160}>
        <Paragraph fontSize={13} color="text.secondary">
          {<FormattedDateTime date={value} />}
        </Paragraph>
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: "lightgrey",
            color: "black",
          }}
        >
          <FromNow date={value} />
        </Small>
      </Box>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>CRYPTO</Box>,
    accessor: "crypto_currency_code",
    Cell: ({ value }) => {
      const { code } = cryptoAssets.find(({ assetID }) => assetID === value);
      console.log("crypto_currency_code", code);
      return (
        <FlexBox>
          <CryptoImg currency={code} size={20} />{" "}
          <Span fontSize={12} fontWeight={500} ml={1}>
            {getCurrencyNameByCode(code)}
          </Span>
        </FlexBox>
      );
    },
    // <CryptoImg currency={value} size={20} />,
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>WITHDRAWAL ADDRESS </Box>,
    accessor: "address",
    Cell: ({ row }) => {
      const { title, address, crypto_currency_code } = row.original;
      const asset = cryptoAssets.find(
        ({ assetID }) => assetID == crypto_currency_code
      );

      return (
        <>
          <Typography fontSize={14} fontWeight={500}>
            {title}
          </Typography>
          <a
            href={`${asset.addressLink}/${address}`}
            target="_blank"
            rel="noreferrer"
            className="transaction-address link-style"
            style={{
              lineHeight: "15px",
              maxWidth: "50rem",
              overflowWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {address}
            <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
              <svg
                width="20"
                height="20"
                viewBox="0 -5 22 22"
                fill="none"
                // xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="16.9497"
                  y="8.46448"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(135 16.9497 8.46448)"
                  fill="currentColor"
                ></rect>
                <path
                  d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
        </>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>STATUS</Box>,
    accessor: "action",
    Cell: ({ value }) => (
      <FlexBox>
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: value === "added" ? "success.main" : "error.main",
          }}
        >
          {value === "added" ? "Added" : "Deleted "}
        </Small>
      </FlexBox>
    ),
  },
];
export default AddressColumnShape;
