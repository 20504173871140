import { useContext } from "react";
import { SettingsContext } from "contexts/settingsContext";

export const LogoDefault = ({ width = 125 }) => {
  const { settings } = useContext(SettingsContext);

  return settings && settings.theme == "light" ? (
    <>
      {" "}
      <img
        className="img-responsive"
        src="/media/logos/logo-default 2.svg"
        alt="logo"
        width={width ? `${width}px` : ""}
      />
    </>
  ) : (
    <>
      {" "}
      <img
        className="img-responsive"
        src="/media/logos/logo-default-white.svg"
        alt="logo"
        width={width ? `${width}px` : ""}
      />
    </>
  );
};

export const LogoMin = ({ width = 30, className }) => {
  const { settings } = useContext(SettingsContext);

  return settings && settings.theme == "light" ? (
    <img
      className="img-responsive"
      src="/media/logos/logo-minimize.svg"
      alt="logo"
      width={width ? `${width}px` : ""}
    />
  ) : (
    <img
      className="img-responsive"
      src="/media/logos/logo-minimize-white.svg"
      alt="logo"
      width={width ? `${width}px` : ""}
    />
  );
};
