import countries from "../data/country";

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export function formatCrypto(amount, base) {
  return toFixed(amount / 10 ** base);
}
// export function normalizeCrypto(
//   amount,
//   currency,
//   ethFrom = "gwei",
//   stable_base = 2
// ) {
//   switch (currency) {
//     case "BTC":
//     case "SOL":
//     case "TON":
//       const sb = require("satoshi-bitcoin");
//       if (amount % 1 != 0) {
//         return amount;
//       }
//       // return sb.toBitcoin(amount);
//       return parseFloat(sb.toBitcoin(amount)).toFixed(8);
//     case "ETH":
//       const convert = require("ethereum-unit-converter");
//       return convert(amount, ethFrom).ether;
//     case "USDT":
//     case "USDC":
//       if (amount > 0) {
//         return formatCrypto(amount, 6).toFixed(stable_base);
//       }
//       return amount;
//     default:
//       return amount;
//   }
// }

export function normalizeCrypto(
  amount,
  currency,
  ethFrom = "gwei",
  stable_base = 2,
  isWallet = false //called for wallet.
) {
  switch (currency) {
    case "BTC":
      const sb = require("satoshi-bitcoin");
      if (amount % 1 != 0) {
        return amount;
      }
      // return sb.toBitcoin(amount);
      return parseFloat(sb.toBitcoin(amount)).toFixed(8); //it is updated because of exponential

    case "ETH":
      const convert = require("ethereum-unit-converter");
      return convert(amount, ethFrom).ether;
    case "USDT":
    case "USDC":
      if (amount > 0) {
        return formatCrypto(amount, 6).toFixed(stable_base);
      }
      return amount;
    case "XMR":
    case "SOL":
    case "TON":
      return convertCryptoUnit(
        amount,
        currency,
        isWallet ? "standard" : "smallest"
      );
    default:
      return amount;
  }
}

function convertCryptoUnit(amount, currency, fromUnit = "smallest") {
  // Define conversion factors for different currencies
  const conversionFactors = {
    SOL: 1000000000,
    TON: 1000000000,
    SMR: 1000000000000, // 1 XMR = 1,000,000,000,000 units
  };

  // Determine the conversion factor based on the currency
  const conversion = conversionFactors[currency] || 1000000000; // Default to SOL if currency is not specified

  // Validate input type
  if (typeof amount !== "number" && typeof amount !== "string") {
    throw new TypeError(
      "convertCryptoUnit must be called on a number or string, got " +
        typeof amount
    );
  }

  // Convert string input to number
  if (typeof amount === "string") {
    amount = parseFloat(amount);
    if (isNaN(amount)) {
      throw new TypeError("Input string must be a valid number");
    }
  }

  // Validate that the amount is a number
  if (typeof amount !== "number" || isNaN(amount)) {
    throw new TypeError("Amount must be a valid number");
  }

  // Perform conversion only if the input is in the smallest unit
  if (fromUnit === "smallest") {
    return amount / conversion; // Divide by the conversion factor
  } else if (fromUnit === "standard") {
    // If the input is already in the standard unit, return it as is
    return amount;
  } else {
    throw new Error('Invalid fromUnit. Must be "smallest" or "standard"');
  }
}

export function formatCurrency(
  amount,
  currencyCode = "USD",
  currencyDisplay = "symbol"
) {
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: currencyDisplay,
  });
  return formatter.format(amount);
}
export function getCurrencyNameByCode(code) {
  const cryptoCurrencies = {
    BTC: "Bitcoin",
    ETH: "Ethereum",
    USDT: "Tether",
    USDC: "USD Coin",
    XLM: "Stellar",
    BNB: "BNB Smart Chain",
    LTC: "Litecoin",
    XRP: "Ripple",
    DAI: "DAI",
    TRX: "TRON",
    WBTC: "Wrapped Bitcoin",
    WETH: "Wrapped Ethereum",
    DOGE: "Dogecoin",
    SOL: "Solana",
    LINK: "Chainlink",
    ADA: "Cardano",
    UNI: "Uniswap",
    TON: "Toncoin",
    XMR: "Monero",
  };
  return cryptoCurrencies[code];
}

export function formatCryptoDecimal(number, decimalPlaces) {
  const formattedNumber = Number(number).toFixed(decimalPlaces);
  return formattedNumber.replace(/\.?0+$/, ""); // Remove trailing zeros
}

export const getTotalBalance = (accounts, currency) => {
  //total by currency
  let balance = 0;
  accounts?.forEach((account) => {
    balance = balance + getCryptoBalance(account, currency);
  });
  return normalizeCrypto(balance, currency, "wei", 6, true);
};

export const getCryptoBalance = (account, currency) => {
  switch (currency) {
    case "BTC":
      const sb = require("satoshi-bitcoin");
      let balance = account.btc_balance
        ? Number(account.btc_balance.balance)
        : 0;
      if (balance % 1 != 0) {
        return balance;
      }
      return sb.toBitcoin(balance);
    case "ETH":
      return account.eth_balance ? Number(account.eth_balance.balance) : 0;
    case "USDT":
      return account.usdt_balance ? Number(account.usdt_balance.balance) : 0;
    case "USDC":
      return account.usdc_balance ? Number(account.usdc_balance.balance) : 0;
    case "SOL":
      return account.sol_balance ? Number(account.sol_balance.balance) : 0;
    case "TON":
      return account.ton_balance ? Number(account.ton_balance.balance) : 0;
    case "XMR":
      return account.xmr_balance ? Number(account.xmr_balance.balance) : 0;
  }
};

export function getCountryNameByCode(code) {
  let countryName;
  countries.forEach((country) => {
    if (country.code == code) {
      countryName = country.name;
    }
  });
  return countryName;
}
