import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { H5, H6 } from "components/Typography";
import React from "react";

function DeleteDialog({
  open,
  title,
  subTitle,
  handleCancel,
  handleConfirm,
  deletButton = "Delete",
  isLoading = false,
}) {
  return (
    <Dialog
      open={open}
      //  onClose={handleClose}
    >
      <DialogTitle
        style={{
          fontSize: "0.925rem",
        }}
      >
        <H5>{title}</H5>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H6> {subTitle}</H6>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          size="small"
          type="submit"
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          size="small"
          type="submit"
          variant="outlined"
          onClick={handleConfirm}
          loading={isLoading}
        >
          {deletButton}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
