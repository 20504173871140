import { Box, Divider, Typography } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import UserSymbol from "components/shared/UserSymbol";
import { Paragraph, Span } from "components/Typography";
import React from "react";
import { CopyButton, VATooltip } from "./Chat";
import ATooltip from "components/shared/ATooltip";
import { formatCurrency } from "utils/currencies";
import UserInfo from "components/shared/UserInfo";
import { Card, CardContent } from "@mui/material";
import { useContext } from "react";
import { SettingsContext } from "contexts/settingsContext";
import { TradeInfo } from "./ShowTradeDetails";

function AllInventory({ trade }) {
  return (
    <>
      <Box mx={1}>
        <FlexBox my={1} sx={{ alignItems: "center" }}>
          <Paragraph mr={0.5} fontSize={15}>
            Inventory for trade v99TFgSLrH9{trade.trade_hash}
          </Paragraph>
        </FlexBox>
      </Box>
      <Box mx={1} mt={2}>
        <div>
          {trade.giftCards.map((card) => (
            <CardInfo
              key={card._id}
              created_by={card.created_by}
              hire_request={card.hire_request}
              {...card.details}
            />
          ))}
        </div>
      </Box>
    </>
  );
}

export default AllInventory;

export const CardInfo = ({
  number,
  cvv,
  expiry,
  amount,
  currency,
  brand,
  pin,
  payment_method_name,
  created_by,
  hire_request,
}) => {
  const { settings } = useContext(SettingsContext);
  console.log("settings CardInfo", settings);
  //   const { profile } = useSelector((store) => store.user);
  return (
    <Card
      sx={{
        p: 2,
        mb: 2,
        boxShadow:
          settings.theme === "dark"
            ? "0px 4px 10px rgba(255, 255, 255, 0.1)" // White shadow for dark mode
            : "0px 4px 10px rgba(0, 0, 0, 0.1)", // Black shadow for light mode
      }}
    >
      <TradeInfo title={"Payment Method"} className={"td-payment-method"}>
        <span>{payment_method_name}</span>
      </TradeInfo>
      <TradeInfo title={"Amount"}>
        <span> {formatCurrency(amount, currency)}</span>
      </TradeInfo>
      <TradeInfo title={"Payment Identifier"}>
        <Span
          fontSize={13}
          sx={{ display: "inline-flex", alignItems: "center" }}
          className="d-inline-flex align-items-center"
        >
          <span>{number}</span>{" "}
          {/* <CopyButton
            iconSize={16}
            onClick={() => copyTextToClipboard(number.replaceAll(" ", ""))}
            showTooltip={false}
            className={"text-hover-primary copy-icon"}
          /> */}
          <CopyButton text={number} />
        </Span>
      </TradeInfo>
      {/* <TradeInfo title={"Issuer"}>
          <img
            className="h-25px"
            src={`/media/card-brands/brand-${brand}.png`}
            alt=""
          />
        </TradeInfo> */}
      {expiry && (
        <TradeInfo title={"Exp Date"}>
          <span>{expiry}</span>
        </TradeInfo>
      )}
      {cvv && <TradeInfo title={"CVV"}>{cvv}</TradeInfo>}
      {pin && <TradeInfo title={"PIN"}>{pin}</TradeInfo>}
      <TradeInfo isLast={true} title={"Added By"}>
        <ATooltip
          content={
            <div className="text-start">
              {created_by.role === "va" ? (
                <>
                  <VATooltip
                    user={created_by}
                    hireRequestId={hire_request}
                    // isVa={profile.role === "va"}
                  />
                </>
              ) : (
                <UserInfo user={created_by} is_live={false} />
              )}
            </div>
          }
        >
          <FlexBox alignItems="center">
            {/* <UserSymbol
              user={created_by}
              size={20}
              fw={"semibold"}
              fs={8}
              showOnline={true}
              //   hireRequestId={hire_request}
              //onlineStatus={isOnline}
            /> */}
            <UserSymbol
              user={created_by}
              size={20}
              showOnline={true}
              isBottomZero={0}
              isVa={created_by.role === "va" ? true : false}
            />
            <Span
              ml={0.5}
              fontSize={13}
              className={`text-gray-700 cursor-default ms-1`}
            >
              {created_by.username}
            </Span>
          </FlexBox>
        </ATooltip>
      </TradeInfo>
    </Card>
  );
};

// export const TradeInfo = ({ title, children, isLast, className }) => {
//   return (
//     <FlexBox alignItems="center" mb={0.5} justifyContent="space-between">
//       <Typography fontSize={13} color={"grey"} fontWeight={500}>
//         {title}
//       </Typography>
//       <Paragraph fontSize={13} fontWeight={500}>
//         {children}
//       </Paragraph>
//     </FlexBox>
//   );
// };
