import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Stack,
  styled,
  Table,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { alpha, Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import Scrollbar from "components/ScrollBar";
import { H4, H5, H6, Paragraph, Small, Tiny } from "components/Typography";
import InvertColors from "icons/InvertColors";
import KeyframeBezierIn from "icons/KeyframeBezierIn";
import PaletteOutlined from "icons/PaletteOutlined";
import { FormattedDate } from "components/shared/FormattedDate";
import { FromNow } from "components/shared/FormattedDate";
import EditButton from "../EditButton";
import DeleteButton from "../DeleteButton";
import DeleteDialog from "components/shared/DeleteDialog";

import { useContext, useEffect, useState } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import Badge, { getRoleColor } from "utils/Badge";
import moment from "moment-timezone";
import Delete from "icons/Delete";
import { Link } from "react-router-dom";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";
import ArrowOutwardButton from "./ArrowOutward";
import NoResultFound from "components/shared/NoResultFound";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import PaxfulAccount from "components/shared/PaxfulAccount";

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const PaxfullAccount = () => {
  // Get data from usercontext api
  const { userdata, setUserData, getUser, account, setAccount } =
    useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //account delete operations
  const confirmDeleteAccount = (id) => {
    setOpen(true);
    setAccountToDelete(id);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    setAccountToDelete(null);
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    const resp = await PaxfulAccountsService.deleteAccount(accountToDelete);
    setOpen(false);
    getUser();
    setLoading(false);
  };

  //relink accout api
  // const relinkAccount = async (userId, vendorId) => {
  //   const resp = await PaxfulAccountsService.relinkAccount(userId, vendorId);
  // };

  //get active vendors

  const getUsers = async (page = 1, limit = 100, type) => {
    const users = await UserService.getUsers(page, limit, type);
    setVendors(users.data);
  };

  useEffect(() => {
    getUsers(1, 100, "active");
  }, []);

  // click on user for account relink
  // const handleUserClick = (vendorId) => {
  //   setSelectedUser(vendorId);
  //   relinkAccount(userdata._id, vendorId);
  //   getUser();
  // };

  //model operations

  const [openModel, setOpenModel] = useState(false);
  const [data, setData] = useState([]);

  const handleOpen = () => setOpenModel(true);
  const handleClose = () => setOpenModel(false);
  const initialValues = {
    vendor: "",
  };
  const { values, errors, handleChange, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values) => {
        setIsLoading(true);
        const resp = await PaxfulAccountsService.relinkAccount(
          userdata._id,
          values
        );
        if (resp && resp.status === true) {
          resetForm();
          handleClose();
          getUser();
        }

        setIsLoading(false);
      },
    });

  const onhandleClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    const deleted_account = userdata?.paxfullAccounts?.filter((account) => {
      if (account.is_deleted) {
        return account;
      }
    });

    // console.log("deleted_account", deleted_account);

    const active_account = userdata?.paxfullAccounts?.filter((account) => {
      if (!account.is_deleted) {
        return account;
      }
    });
    // console.log("active_account", active_account);
    setData(active_account.concat(deleted_account));
  }, []);

  const handleTabChange = (event) => {
    setAccount(event.target.value);

    getUser(event.target.value);
  };
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <FlexBox justifyContent="space-between" alignItems="center">
        <H5 mb={1}> P2P Accounts</H5>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={account}
              onChange={(e) => handleTabChange(e)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"active"}>
                {" "}
                <Paragraph>Active</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"delete"}>
                {" "}
                <Paragraph>Deleted</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </FlexBox>

      <Scrollbar autoHide={false}>
        <Table>
          {/* <TableHead>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={500}>
                Email
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={500}>
                Email
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={500}>
                Email
              </Tiny>
            </StyledTableCell>
          </TableHead> */}
          <TableBody>
            {userdata?.paxfullAccounts?.length == 0 ? (
              <TableRow
                style={{
                  textAlign: "center",
                }}
              >
                <StyledTableCell colSpan={3} align="center">
                  <NoResultFound />
                </StyledTableCell>
              </TableRow>
            ) : (
              userdata?.paxfullAccounts &&
              data.map((item) => (
                <TableRow>
                  <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                      <PaxfulAccount item={item} size={40} />
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <Box>
                      <Tiny fontSize={12} fontWeight={500}>
                        {item.is_delegate || item.was_delegate
                          ? "Connected via Fast Connect"
                          : " Connected via API Keys"}
                      </Tiny>
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell sx={{ display: "flex" }} align="right">
                    <FlexBox justifyContent="end">
                      {item.is_deleted ? null : (
                        <DeleteButton
                          onClick={() => confirmDeleteAccount(item._id)}
                        />
                      )}
                    </FlexBox>
                    {/* Relink Button */}
                    {/* <StyledButton
                      onClick={handleOpen}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      Relink
                    </StyledButton> */}
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Scrollbar>
      {/* <Dialog
        open={open}
        //  onClose={handleClose}
      >
        <DialogTitle
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H5>Confirm Delete</H5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontSize: "0.925rem",
            }}
          >
            <H6> will delete this user permanently. Are you sure?</H6>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirm}>Cancel</Button>
          <Button onClick={() => handleDeleteAccount()}>Delete</Button>
        </DialogActions>
      </Dialog> */}
      <DeleteDialog
        open={open}
        title={"Confirm Delete"}
        subTitle={"It will delete this account permanently. Are you sure?"}
        handleCancel={handleCancelConfirm}
        handleConfirm={() => handleDeleteAccount()}
        isLoading={loading}
      />

      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

          <form onSubmit={handleSubmit}>
            <FormControl sx={{ width: 290, padding: 0 }}>
              <H4 mb={1}>Vendors</H4>
              <Paragraph
                mb={1}
                fontWeight={600}
                fontSize={12}
                color={"text.secondary"}
              >
                select a vendor for relink this account
              </Paragraph>

              <Select
                displayEmpty
                inputProps={{ "aria-label": "Select an option" }}
                sx={{ height: 39 }}
                onChange={handleChange}
                name="vendor"
                value={values.vendor}
              >
                <MenuItem value="" disabled>
                  Select a Vendor
                </MenuItem>
                {vendors &&
                  vendors.map(({ _id, first_name, last_name }) => (
                    <MenuItem key={first_name} value={_id}>
                      {`${first_name + " " + last_name}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
              <Button
                fullWidth
                size="small"
                variant="outlined"
                onClick={onhandleClose}
              >
                Cancel
              </Button>
              <LoadingButton
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </FlexBox>
          </form>
          {/* <ul>
            {vendors &&
              vendors.map((vendor) => (
                <li
                  key={vendor._id}
                  style={{
                    background:
                      selectedUser === vendor._id ? "lightblue" : "white",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUserClick(vendor._id)}
                >
                  {vendor.first_name}
                </li>
              ))}
          </ul> */}
        </Box>
      </Modal>
    </Card>
  );
};

export default PaxfullAccount;
