import { UserContextData } from "App";
import cryptoAssets from "data/supportedAssets";
import { useContext, useMemo } from "react";
import { formatCurrency } from "./currencies";

export function getAtlantisAssetBalance(asset_balance) {
  let totalBalance = 0;
  const assetBalance = [];

  Object.keys(asset_balance).forEach((asset) => {
    const { crypto_balance } = asset_balance[asset];
    const fiat_balance = convertToFiat(crypto_balance, asset);

    assetBalance.push({
      asset: asset,
      balance_crypto: crypto_balance,
      fiat_balance: fiat_balance,
    });

    //ToDo - currency conversions
    totalBalance += fiat_balance;
  });
  return { totalBalance, assetBalance };
}

export const convertToFiat = (amount, asset) => {
  const code =
    cryptoAssets && cryptoAssets.find(({ assetID }) => assetID == asset)?.rate;
  const rate = GetFiatRate(code);
  return amount * rate;
};

// export const GetFiatRate = (crypto) => {
//   console.log("crypto", crypto);
//   const { liveRate } = useContext(UserContextData);
//   console.log("liveRate", liveRate);
//   if (liveRate) {
//     return crypto ? liveRate[crypto] : 1;
//   }
//   return 0;
// };
export const GetFiatRate = (crypto, currency = "USD") => {
  const { liveRate } = useContext(UserContextData);
  console.log("rate", liveRate);
  if (liveRate?.length > 0) {
    const live_rates = liveRate.find(({ base }) => base == currency);
    return crypto && live_rates ? live_rates.rates[crypto] : 1;
  }
  return 0;
};

// export const getFormatedFiatAmount = (amount, asset) => {
//   return formatCurrency(convertToFiat(amount, asset));
// };
export const getFormatedFiatAmount = (amount, asset, currency = "USD") => {
  const fiatAmt = convertToFiat(amount, asset, currency);
  return formatCurrency(fiatAmt, currency);
};

export function FiatAmount({ usdAmount, profile, code = false }) {
  //const [rate, setRate] = useState(null);
  const { rate } = useContext(UserContextData);

  const _rate = useMemo(() => {
    if (profile && rate) {
      const currencyRate = rate.find(({ code }) => code === profile.currency);
      console.log("currencyRate", currencyRate);
      return currencyRate.rate.usd;
    }
  }, [profile, rate]);
  console.log("rate/*/*/*", _rate);

  return (
    _rate && (
      <>
        {formatCurrency(_rate * usdAmount, profile.currency)}
        {code && <> {profile.currency}</>}
      </>
    )
  );
}

export function formatCryptoByCode(amount, currency) {
  switch (currency) {
    case "BTC":
      //satoshi
      return Number(amount).toFixed(8);
    case "ETH":
      //gwei
      return Number(amount).toFixed(9);
    case "USDT":
      //microcents
      return Number(amount).toFixed(6);
    case "USDC":
      return Number(amount).toFixed(6);
    case "SOL":
      return Number(amount).toFixed(8);
    case "TON":
      return Number(amount).toFixed(9);
    case "XMR":
      return Number(amount).toFixed(9);
  }
}

export function FormatCurrencyCrypto({ totalCryptoAmount, profile, code }) {
  const { liveRate } = useContext(UserContextData);
  const rate = useMemo(() => {
    if (profile && liveRate) {
      const currencyRate = liveRate.find(
        ({ base }) => base === profile.currency
      );
      return currencyRate;
    }
  }, [profile, liveRate]);

  return (
    <>
      {rate.rates &&
        formatCurrency(
          totalCryptoAmount * rate.rates[code],
          profile.currency
        )}{" "}
      {profile.currency}
    </>
  );
}
