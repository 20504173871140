import { Box } from "@mui/material";
import { Span } from "components/Typography";
import { VenvaTag } from "page-sections/profiles/buyer/PartnerTag";
import React, { useEffect, useId, useRef, useState } from "react";
import buyerService from "services/buyer";

//partner = partner id
function TradeTag({ trade, className, showNoResult = false }) {
  const [tradeTags, setTradeTags] = useState(trade.tags ? trade.tags : []);

  //   const id = useId();

  //   const addPartnerTag = (tag) => {
  //     setPartnerTags([...partnerTags, tag]);
  //   };

  //   const removePartnerTag = (tag) => {
  //     setPartnerTags([...partnerTags].filter((_tag) => _tag._id !== tag._id));
  //   };

  //   const handleAddNewItem = () => {
  //     tagSelectorRef.current.open();
  //   };

  //   const handleSelectTag = async (tag) => {
  //     addPartnerTag(tag);
  //     await TagsService.assignTag(tag._id, {
  //       item_id: partner,
  //       type: "partner",
  //     });
  //   };

  //   const handleRemoveTag = async (tag) => {
  //     removePartnerTag(tag);
  //     await TagsService.removeTag(tag._id, {
  //       item_id: partner,
  //       type: "partner",
  //     });
  //   };

  useEffect(() => {
    setTradeTags(trade.tags);
  }, [trade._id, trade.tags]);

  // useEffect(() => {
  //   return () => {
  //     setPartnerTags([]);
  //   };
  // }, []);

  return (
    <>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          columnGap: "5px",
          rowGap: "5px",
          marginTop: "0.5rem",
          marginBottom: "0.8rem",
        }}
        className={`partner-tags${className ? ` ${className}` : ""}`}
      >
        {trade.tags?.map((tag) => (
          <li key={tag._id}>
            <VenvaTag label={tag.name} color={tag.color} />
          </li>
        ))}
        {showNoResult && trade.tags?.length == 0 && (
          <li>
            <Span fontSize={12} fontWeight={500}>
              No applied tags
            </Span>
          </li>
        )}
      </ul>
    </>
  );
}

export default TradeTag;
