import React from "react";
import PaxfulProfilePic from "./PaxfulProfilePic";
import ATooltip from "./ATooltip";
import { normalizeCrypto } from "utils/currencies";
import FlexBox from "components/flexbox/FlexBox";
import CryptoImg from "./CryptoImg";
import { Box } from "@mui/material";

function CryptoBalance({
  code,
  amount,
  offer_type,
  fs,
  status,
  isPartner,
  partner,
  user,
}) {
  // const getAmountStatus = (offer_type, status) => {
  //   switch (offer_type) {
  //     case "buy":
  //       return status == "new"
  //         ? `${user.username} are receiving`
  //         : `${user.username} received`;
  //     case "sell":
  //       return status == "new"
  //         ? `${user.username} are paying`
  //         : `${user.username} paid`;
  //   }
  // };
  const getAmountStatus = (offer_type, status) => {
    switch (offer_type) {
      case "buy":
        return status === "cancelled"
          ? `${user.username} would have paid`
          : status === "released"
          ? `${user.username} are receiving`
          : `${user.username} is paying`;
      case "sell":
        return status === "cancelled"
          ? `${user.username} would have paid`
          : status === "released"
          ? `${user.username} paid`
          : `${user.username}  is paying`;
      default:
        return "";
    }
  };

  const getPartnerAmountStatus = (offer_type) => {
    switch (offer_type) {
      case "buy":
        return (
          <>
            <PaxfulProfilePic
              account={partner}
              size={15}
              buyer
              className={"me-1"}
            />
            {partner.username} paid
          </>
        );

      case "sell":
        return (
          <>
            <PaxfulProfilePic
              account={partner}
              size={15}
              buyer
              className={"me-1"}
            />
            {partner.username} received
          </>
        );
    }
  };

  return (
    <>
      <ATooltip
        content={
          <>
            <FlexBox
              sx={{
                alignItems: "center",
              }}
            >
              <FlexBox sx={{ alignItems: "center" }}>
                <Box>
                  {!isPartner
                    ? getAmountStatus(offer_type, status)
                    : getPartnerAmountStatus(offer_type)}{" "}
                  {normalizeCrypto(amount, code)} {code} &nbsp;
                  <CryptoImg currency={code} size={15} />
                </Box>
              </FlexBox>
            </FlexBox>
          </>
        }
      >
        <span className={`fs-${fs}`}>
          <FlexBox sx={{ alignItems: "center" }}>
            <Box mr={1} mt={1}>
              <CryptoImg currency={code} size={15} />
            </Box>
            {normalizeCrypto(amount, code)} {code}
          </FlexBox>
        </span>
      </ATooltip>
    </>
  );
}

CryptoBalance.defaultProps = {
  fs: "7",
  isPartner: false,
};

export default CryptoBalance;
