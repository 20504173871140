import { Box } from "@mui/material";
import { Span } from "components/Typography";
import React, { useEffect, useId, useRef, useState } from "react";
import buyerService from "services/buyer";

//partner = partner id
function PartnerTags({
  partner,
  tags = null,
  className,
  showNoResult = false,
}) {
  const [partnerTags, setPartnerTags] = useState(tags ? tags : []);

  //   const id = useId();

  //   const addPartnerTag = (tag) => {
  //     setPartnerTags([...partnerTags, tag]);
  //   };

  //   const removePartnerTag = (tag) => {
  //     setPartnerTags([...partnerTags].filter((_tag) => _tag._id !== tag._id));
  //   };

  //   const handleAddNewItem = () => {
  //     tagSelectorRef.current.open();
  //   };

  const getPartnerTags = async (buyer_id) => {
    const resp = await buyerService.getPatnerTagsByBuyerId(buyer_id);
    if (resp.status) {
      setPartnerTags(resp.data);
    }
  };

  //   const handleSelectTag = async (tag) => {
  //     addPartnerTag(tag);
  //     await TagsService.assignTag(tag._id, {
  //       item_id: partner,
  //       type: "partner",
  //     });
  //   };

  //   const handleRemoveTag = async (tag) => {
  //     removePartnerTag(tag);
  //     await TagsService.removeTag(tag._id, {
  //       item_id: partner,
  //       type: "partner",
  //     });
  //   };

  useEffect(() => {
    console.log("tags", tags);
    if (!tags) {
      getPartnerTags(partner);
    }
    setPartnerTags(tags);
  }, [partner, tags]);

  // useEffect(() => {
  //   return () => {
  //     setPartnerTags([]);
  //   };
  // }, []);

  return (
    <>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          columnGap: "5px",
          rowGap: "5px",
          marginTop: "0.5rem",
          marginBottom: "0.8rem",
        }}
        className={`partner-tags${className ? ` ${className}` : ""}`}
      >
        {partnerTags &&
          partnerTags.map((tag) => (
            <li key={tag._id}>
              <VenvaTag label={tag.name} color={tag.color} />
            </li>
          ))}
        {!showNoResult && partnerTags && partnerTags.length == 0 && (
          <li>
            <Span fontSize={12} fontWeight={500}>
              No applied tags
            </Span>
          </li>
        )}
      </ul>
    </>
  );
}

export default PartnerTags;

export function VenvaTag({ color, label, showRemove, onRemove }) {
  return (
    <Box
      sx={{
        display: "inline-block",
        padding: "2px 12px",
        borderRadius: "15px",
      }}
      className={`venva-custom-tag${showRemove ? "has-remove" : ""}`}
      style={{ color: color?.text, backgroundColor: color?.background }}
    >
      <Span fontSize={13} fontWeight={500}>
        {label}
      </Span>
      {showRemove && (
        <a className="remove-tag" onClick={onRemove}>
          <i class="bi bi-x"></i>
        </a>
      )}
    </Box>
  );
}
