import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import CustomChip from "components/shared/CustomChip";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import ATooltip from "components/shared/ATooltip";
import CheckmarkCircle from "icons/CheckmarkCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag, { getCountry } from "components/shared/CountryFlag";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import DeleteButton from "page-sections/profiles/profile/DeleteButton";
import EditButton from "page-sections/profiles/profile/EditButton";
import PasswordButton from "page-sections/profiles/profile/PasswordButton";
import RoleBadge from "utils/RoleBadge";
import LockButton from "page-sections/profiles/profile/overview/LockButton";
import DiscordAvatar from "components/avatars/DiscordAvatar";
import GoogleIcon from "icons/GoogleIcon";
import SignInMethod from "components/shared/SignInMethod";
import notification from "helpers/notifications";
import PersonOffButton from "page-sections/profiles/profile/SessionButton";
import { formatCurrency, getCurrencyNameByCode } from "utils/currencies";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { useMemo, useState } from "react";
import TradePartnerDetails from "page-sections/profiles/buyer/TradePartnerDetails";
import { isCountryCodeExists, isCountryNameExists } from "utils/utils";
import cryptoAssets from "data/supportedAssets";
import CryptoImg from "components/shared/CryptoImg";
import { VenvaTag } from "page-sections/profiles/buyer/PartnerTag";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)`
      padding: 4,
      minWidth: 60,
      marginRight: 3,
    `;

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

// anchorEl,
// handleClick,
// _handleClose,
// _open,
// id
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

export function TagType({ type = "trade" }) {
  const types = useMemo(() => {
    return {
      partner: "Trade Partner",
      offer: "Offer",
      trade: "Trade",
      "gift-card": "Inventory",
    };
  }, []);

  return <span>{types[type]}</span>;
}

const TagColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>NAME </Box>,
    accessor: "name",
    Cell: ({ value }) => {
      return (
        <>
          <Typography fontSize={14} fontWeight={500}>
            {value}
          </Typography>
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>TYPE </Box>,
    accessor: "type",
    Cell: ({ value }) => {
      return (
        <>
          <Typography fontSize={14} fontWeight={500}>
            <TagType type={value} />
          </Typography>
        </>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>TAG COLOR</Box>,
    accessor: "color",
    Cell: ({ row }) => {
      const { color } = row.original;
      return (
        <>
          <Box
            sx={{
              background: color.background,
              height: "25px",
              width: "25px",
              borderRadius: "5px",
            }}
          ></Box>
        </>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>TEXT COLOR</Box>,
    accessor: "TEXT COLOR",
    Cell: ({ row }) => {
      const { color } = row.original;

      return (
        <>
          <Box
            sx={{
              background: color.text,
              height: "25px",
              width: "25px",
              borderRadius: "5px",
              border: "1px solid #e4e4e4",
            }}
          ></Box>
        </>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>PREVIEW </Box>,
    accessor: "PREVIEW",
    Cell: ({ row }) => {
      const { color, name } = row.original;
      return (
        <>
          <VenvaTag
            label={name}
            color={color}
            // showRemove={true}
            // onRemove={() => handleRemoveTag(tag)}
          />
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>DESCRIPTION </Box>,
    accessor: "description",
    Cell: ({ value }) => {
      return (
        <>
          <Typography fontSize={14} fontWeight={500}>
            {value}
          </Typography>
        </>
      );
    },
  },

  // {
  //   Header: () => <Box sx={{ marginLeft: "10px" }}>STATUS</Box>,
  //   accessor: "action",
  //   Cell: ({ value }) => (
  //     <FlexBox>
  //       <Small
  //         sx={{
  //           width: 100,
  //           fontSize: 10,
  //           color: "#fff",
  //           borderRadius: "4px",
  //           textAlign: "center",
  //           padding: ".2rem 1rem",
  //           backgroundColor: value === "added" ? "success.main" : "error.main",
  //         }}
  //       >
  //         {value === "added" ? "Added" : "Deleted "}
  //       </Small>
  //     </FlexBox>
  //   ),
  // },
];
export default TagColumnShape;
