import axios from "../utils/axios";

const TagService = {};

TagService.getTag = function (id, page, limit, type) {
  return axios({
    url: `/tags/${id}?page=${page}&limit=${limit}&type=${type}`,
    method: "GET",
  });
};

export default TagService;
