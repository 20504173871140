import { ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  styled,
  Table,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppPagination from "components/AppPagination";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { H5, H6, Paragraph } from "components/Typography";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayIsEmpty } from "utils/utils";
import UserRequestApi from "services/userRequestService";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import ColumnShapeMasterList from "./ColumnShapeMasterList";
import UserService from "services/UserService";
import PasswordModal from "page-sections/data-table/PasswordModal";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import DeleteDialog from "components/shared/DeleteDialog";
// import ViewActivityModal from "./view";

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const UserMasterListAll = ({
  data,
  num_pages,
  onPageChange,
  currentPage,
  handleLimitChange,
  limit,
  getUserMasterList,
  option,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // For Delete User
  const [userToDelete, setUserToDelete] = useState(null);
  const [user, setUser] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const [isLoading, setiIsLoading] = useState(false);

  const handleCancelConfirm = () => {
    setOpen(false);
    setUserToDelete(null);
  };
  const confirmDeleteUser = ({ _id }, action) => {
    setUserAction(action);
    setOpen(true);
    setUserToDelete(_id);
  };

  console.log("userToDelete", userToDelete);

  const handleDeleteUser = async () => {
    setiIsLoading(true);

    ////api call================================================
    let resp;
    switch (userAction) {
      case "delete":
        resp = await UserService.DeleteUser(userToDelete);
        break;
      case "session":
        resp = await UserService.sessionExpired(userToDelete);
        break;
    }

    if (resp.status) {
      setOpen(false);
      getUserMasterList(1, 50, option, "");
    }
    setiIsLoading(false);
  };

  // For Change Password

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const changePassword = (user) => {
    setOpenPasswordModal(true);
    setUser(user);
  };

  // Edit Modal

  const [openEditModal, setOpenEditModal] = useState(false);

  const editUser = (user) => {
    setOpenEditModal(true);
    setUser(user);
  };

  const [openMFA, setOpenMFA] = useState(false);

  const disableMFA = (user) => {
    setOpenMFA(true);
    setUser(user);
  };
  const handleClose = () => {
    setOpenMFA(false);
  };
  const ConfirmDisableMFA = async () => {
    console.log("user", user._id);
    console.log("user mfa disable");
    await UserService.DisableMFA(user._id);
    setOpenMFA(false);
    getUserMasterList(1, 50, option, "");
  };

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   // console.log("setAnchorEl");
  //   setAnchorEl(event.currentTarget);
  // };

  // const _handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const _open = Boolean(anchorEl);
  // const id = _open ? "simple-popover" : undefined;
  // const onCellClick = (id) => {
  //   console.log(`Cell clicked: ${id}`);
  //   navigate(`/dashboard/buyers/${id}`);
  // };
  const tableData = useMemo(() => data, [data]);
  // anchorEl, handleClick, _handleClose, _open, id

  const columns = useMemo(
    () =>
      ColumnShapeMasterList(
        confirmDeleteUser,
        changePassword,
        editUser,
        disableMFA,
        option
      ),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: tableData, //UserRequestData
      pageCount: num_pages,
      manualPagination: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination

  // console.log(pageSize);
  // const handleChange = () => gotoPage(invoiceList.length);
  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  // Save the state for open and close the model
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleViewButton = (webhook) => {
    setOpenModal(true);
    setSelectedRecord(webhook);
  };

  const title = (value) => {
    console.log("value", value);
    let modal = {
      title: "Delete User",
      subtext: "It will delete user permanently. Are you sure?",
      buttonText: "Delete",
    };
    switch (value) {
      case "session":
        modal.title = "Expire Sessions";
        modal.subtext = "It will expire user sessions. Are you sure?";
        modal.buttonText = "Expire";
        break;
    }
    return modal;
  };

  // console.log(tableData);
  return (
    <Box>
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={8} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    // sx={{
                    //   cursor: "pointer",
                    // }}
                    // Click to open a Model
                    // onClick={() => {console.log("Click On Edit")}}
                    onClick={() => handleViewButton(row.original)}
                  >
                    {row.cells.map((cell, index) => (
                      <BodyTableCell key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </BodyTableCell>
                    ))}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
          <DeleteDialog
            open={open}
            title={title(userAction)?.title}
            subTitle={title(userAction)?.subtext}
            handleCancel={handleCancelConfirm}
            handleConfirm={() => handleDeleteUser()}
            deletButton={title(userAction)?.buttonText}
            isLoading={isLoading}
          />
        </Table>
      </Scrollbar>

      <PaginanationAndLimit
        tableData={tableData}
        handleChange={handleChange}
        count={pageOptions.length}
        currentPage={currentPage}
        handleLimitChange={handleLimitChange}
        limit={limit}
      />

      {user && (
        <PasswordModal
          open={openPasswordModal}
          onClose={() => {
            setOpenPasswordModal(false);
            setUser(null);
          }}
          data={user}
        />
      )}
      {user && (
        <AddEmployeeModal
          onSuccess={getUserMasterList}
          subuser={true}
          data={user}
          edit={true}
          open={openEditModal}
          onClose={() => {
            setOpenEditModal(false);
            setUser(null);
          }}
          // getUserTeamMember={() => getUserTeamMember(userdata._id, 0)}
        />
      )}

      <Dialog
        open={openMFA}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H5>MFA Disable</H5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontSize: "0.925rem",
            }}
          >
            <H6>It will disable MFA to the user. Are you Sure?</H6>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => ConfirmDisableMFA()}>Remove</Button>
        </DialogActions>
      </Dialog>
      {/* Model Open And Close */}
      {/* {selectedRecord && (
        <ViewActivityModal
          data={selectedRecord}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )} */}
      {/* 
      <FlexBetween>
        <Paragraph color="text.secondary" fontSize={13}>
          Showing 1-12 of {pageCount * state.pageSize} result
        </Paragraph>

        <ButtonBase
          onClick={handleChange}
          disableRipple
          sx={{
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          View All
          <ArrowRightAlt
            sx={{
              marginLeft: 0.5,
            }}
          />
        </ButtonBase>
      </FlexBetween> */}

      {/* <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </Box>
  );
};

export default UserMasterListAll;
