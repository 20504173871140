import {
  // ArrowBack,
  // ChevronRight,
  // ConstructionOutlined,
  // ReportRounded,
  // Rowing,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Button } from "@mui/material";
//import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import SearchInput from "components/input-fields/SearchInput";
import Scrollbar from "components/ScrollBar";
import { H4, H5, Paragraph, Small, Tiny, Span } from "components/Typography";
import AttachmentDiagonal from "icons/AttachmentDiagonal";
//import FileOutlined from "icons/FileOutlined";
//import Link from "icons/Link";
//import Common from "page-sections/chats/chat-v1/Common";
//import FileType from "page-sections/chats/chat-v1/FileType";
//import IncomingMsg from "page-sections/chats/chat-v1/IncomingMsg";
//import OutgoingMsg from "page-sections/chats/chat-v1/OutgoingMsg";
//import PersonInfo from "page-sections/chats/chat-v1/PersonInfo";
//import RecentChatItem from "page-sections/chats/chat-v1/RecentChatItem";
import { useState, useEffect, useRef } from "react";
//import RECENT_CHATS from "__fakeData__/recent_chats";
// import { lightTheme } from "../../constants"; // styled components
import { lightTheme } from "../../../../constants";
//import { H3, H6 } from "components/Typography";
//import TradeStatus from "components/shared/TradeStatus";
import TradeServices from "services/TradeService";
//import { useParams } from "react-router";
//import UserService from "services/UserService";
import { useContext } from "react";
//import { UserContext } from "page-sections/user-list/ViewUser";
import {
  //FormattedDate,
  //FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
//import OfferType from "components/shared/OfferType";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import UserSymbol from "components/shared/UserSymbol";
//import { formatCurrency, normalizeCrypto } from "utils/currencies";
//import Badge from "utils/Badge";
//import LastActive from "components/shared/LastActive";
import { SERVER_BASE_URL } from "config";
//import Moment from "react-moment";
import Attachments from "./Attachments";
import { API_BASE_URL } from "config";
//import { COMMON_API_BASE_URL } from "config";
//import InfoIcon from "@mui/icons-material/Info";
import PartnerDetail, { FlagAndPlatform } from "./PartnerDetail";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ATooltip from "components/shared/ATooltip";
import UserInfo from "components/shared/UserInfo";
import Timezone from "components/shared/Timezone";
import CountryFlag from "components/shared/CountryFlag";
//import VAProfilePic from "components/shared/VAProfilePic";
//import VaSchedule from "components/shared/VaSchedule";
//import HiringRequestService from "services/HireRequestService";
import Platform from "components/shared/Platform";
import { copyTextToClipboard, isCountryCodeExists } from "utils/utils";
import { ImageGalleryContext } from "components/ImageGalleryContext";
//import CustomPopover from "components/CustomPopover";
import { SettingsContext } from "contexts/settingsContext";
import DateTime from "components/shared/DateTime";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import QRCode from "components/QrCode";
import BadgeMui from "components/shared/BadgeMui";
import { Image } from "./Image";
import platforms from "data/platforms";
import FeaturedMessagesList from "./FeaturedMessagesList";
//import { VASchedule } from "page-sections/hiring-request/ColumnShape";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import PastTradeSidebar from "./PastTradeSidebar";

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));
const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  backgroundColor: lightTheme(theme)
    ? theme.palette.action.hover
    : theme.palette.divider,
}));
const ToggleBtn = styled(Box)(({ theme, screen = "md" }) => ({
  top: 20,
  zIndex: 1,
  padding: 5,
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up(screen)]: {
    display: "none",
  },
}));
export const CopyButton = ({ text }) => {
  return (
    <a
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        copyTextToClipboard(text);
      }}
    >
      <span className="svg-icon svg-icon-gray-600 svg-icon-4 ms-2">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </a>
  );
};

const Message = ({
  message,
  seller,
  buyer,
  account,
  trade_id,
  onImageSelect,
  is_webhook_msg,
  delivered,
  shareBankAccount,
  bank_accounts,
  amount,
  bank_account_shared,
  bank_account_shared_by,
  offer_type,
  proof_uploaded,
  proof_uploaded_by,
  onProofUpload,
  payment_method_slug,
  disabled,
  is_automated,
  automated_type,
  doc_type,
  vaActivity,
  hireRequestId,
  sent_by_va,
  sent_by,
  paid_by,
  trade,
}) => {
  const { settings } = useContext(SettingsContext);
  // for test
  // const getVa = async () => {
  //   let resp = await HiringRequestService.getHireRequest(1, 100, "active", "");
  //   console.log("HiringRequestService.getHireRequest", resp);
  // };

  // useEffect(() => {
  //   getVa();
  // }, []);
  console.log("paid_by", paid_by);

  const is_sent = message.author == seller.paxful_username;
  let messageContent;

  const [openBankDetails, setOpenBankDetails] = useState(false);
  const toggleBankDetails = () => {
    setOpenBankDetails(!openBankDetails);
  };

  let initial_images = message.text.files;
  let more_images = [];
  if (message.text.files?.length > 3) {
    initial_images = message.text.files.slice(0, 4);
    more_images = message.text.files.slice(4);
  }

  if (["security_awareness", "bank-account"].indexOf(message.type) > -1) {
    return null;
  }
  console.log("message.type", message.type);
  if (message.type == "trade_attach_uploaded") {
    messageContent = initial_images.map(
      ({ file_id, full_url, type }, index) => {
        let file_path = file_id
          ? `${API_BASE_URL}/files/s3/${file_id}`
          : `${SERVER_BASE_URL}/${full_url}`;
        const lastIndex = initial_images.length == index + 1;
        // let file_path;
        // const lastIndex = initial_images.length == index + 1;
        // if (is_webhook_msg) {
        //   let url = new URL(full_url);
        //   let image_hash = url.pathname.substring(
        //     url.pathname.lastIndexOf("/") + 1
        //   );
        //   file_path = `${COMMON_API_BASE_URL}/uploads/paxful/attachments/${message.trade_hash}/${image_hash}`;
        // }

        if (type == 1) {
          // classes.push("is-image");
          //image
          // let image = `${file_path}.png`;
          // if (!is_webhook_msg) {
          //   image = `${COMMON_API_BASE_URL}/${full_url}`;
          // }
          return (
            <>
              <a
                role={"button"}
                key={index}
                onClick={() => {
                  onImageSelect(file_path);
                }}
                className="cursor-pointer"
              >
                <Image
                  height={125}
                  width={200}
                  image={file_path}
                  className={"chat-image"}
                  alt={"image"}
                />
                {lastIndex && more_images.length >= 1 && (
                  <Small className="more-images">
                    +{more_images.length + 1}
                  </Small>
                )}
              </a>
              {/* <FsligthGalleryForSingleImage
              index={index}
              image={image}
              toggler={toggler}
            /> */}
            </>
          );
        }
        if (type == 3) {
          // classes.push("is-pdf");
          // let pdf = `${file_path}.png`;
          // if (!is_webhook_msg) {
          //   pdf = `${COMMON_API_BASE_URL}/${full_url}`;
          // }

          return (
            <a
              key={index}
              onClick={() => {}}
              href={file_path}
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-80px" src="/media/svg/files/pdf.svg" />
            </a>
          );
        }
        return null;
      }
    );
  } else if (message.type == "trade_info") {
    messageContent = (
      <span
        dangerouslySetInnerHTML={{
          __html: `<span><strong class="fw-semibold"><i class="bi bi-info-circle text-primary fs-5"></i> Follow these instructions from your trade partner:</strong> ${message.text.replace(
            /\n/g,
            "<br />"
          )}</span>`,
        }}
      ></span>
    );
  } else if (message.type == "bank-account-instruction") {
    messageContent = (
      <>
        <Box mb={1}>
          {offer_type == "sell" && (
            <strong className="fw-semibold">
              A payment of {amount} {bank_accounts.to?.fiat_currency_code} is
              being made to your bank account:
            </strong>
          )}
          {offer_type == "buy" && (
            <strong className="fw-semibold">You need to send money to:</strong>
          )}
        </Box>
        <div className="separator my-2"></div>
        <FlexBox className="d-flex">
          <Small
            fontSize={13}
            width={"125px"}
            className="w-125px text-gray-600"
          >
            Amount
          </Small>
          <Small fontSize={13} className="fw-semibold">
            {amount} {bank_accounts.to?.fiat_currency_code}
          </Small>
          <CopyButton text={amount} />
        </FlexBox>
        {bank_accounts.to?.holder_name && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Account Holder
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.holder_name}
            </Small>
            <CopyButton text={bank_accounts.to?.holder_name} />
          </FlexBox>
        )}
        {bank_accounts.to?.bank_name && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Bank Name
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.bank_name}
            </Small>
            <CopyButton text={bank_accounts.to?.bank_name} />
          </FlexBox>
        )}
        {bank_accounts.to?.branch && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Branch
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.branch}
            </Small>
            <CopyButton text={bank_accounts.to?.branch} />
          </FlexBox>
        )}
        {bank_accounts.to?.account_number && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Account Number
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.account_number}
            </Small>
            <CopyButton text={bank_accounts.to?.account_number} />
          </FlexBox>
        )}
        {bank_accounts.to?.ifsc && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              IFSC
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.ifsc}
            </Small>
            <CopyButton text={bank_accounts.to?.ifsc} />
          </FlexBox>
        )}
        {bank_accounts.to?.routing_number && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Routing Number
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.routing_number}
            </Small>
            <CopyButton text={bank_accounts.to?.routing_number} />
          </FlexBox>
        )}
        {bank_accounts.to?.clabe && (
          <FlexBox className="d-flex">
            <Small
              fontSize={13}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Clabe
            </Small>
            <Small fontSize={13} className="fw-semibold">
              {bank_accounts.to?.clabe}
            </Small>
            <CopyButton text={bank_accounts.to?.clabe} />
          </FlexBox>
        )}

        {bank_account_shared_by && (
          <div className="mt-1 text-end  share-by">
            <div className=" align-items-center ms-0 fs-7 text-gray-500 fw-semibold ">
              <small className="me-1 mt-2">Shared By:</small>{" "}
              {bank_account_shared_by.role === "va" ? (
                <ATooltip
                  content={
                    <VATooltip
                      user={bank_account_shared_by}
                      hireRequestId={hireRequestId}
                    />
                  }
                >
                  <a className="text-primary cursor-default position--relative trade-visitor sharedby-cursor">
                    <span>{bank_account_shared_by.username}</span>
                  </a>
                </ATooltip>
              ) : (
                <ATooltip
                  content={
                    <UserInfo
                      user={bank_account_shared_by}
                      showOnline={true}
                      is_live={false}
                    />
                  }
                >
                  <a className="text-primary cursor-default position-relative trade-visitor sharedby-cursor">
                    <span>{bank_account_shared_by.username}</span>
                  </a>
                </ATooltip>
              )}
            </div>
          </div>
        )}
      </>
    );
  } else if (message.type == "bank-account-share") {
    messageContent = (
      <>
        <span>
          <strong className="fw-semibold">
            Share your selected bank account details with the buyer?
          </strong>
          <div>
            <Button
              disabled={bank_account_shared || disabled}
              id="share_bank_account"
              color="primary"
              className="mt-2 open-popover btn-style"
              size="sm"
              onClick={toggleBankDetails}
              style={{ background: "light-gray" }}
            >
              Share
            </Button>
          </div>
        </span>
        {/* {bank_accounts && (
          <div ref={ref}>
            <BankDetails
              bank_accounts={bank_accounts}
              isOpen={openBankDetails}
              target={"share_bank_account"}
              onClick={() => setOpenBankDetails(false)}
              shareBankAccount={shareBankAccount}
              placement={"right"}
              amount={amount}
              isPopover
              hasCancel={true}
            />
          </div>
        )} */}
      </>
    );
  } else if (
    message.type == "marked_paid" &&
    payment_method_slug == "bank-transfer" &&
    offer_type == "buy"
  ) {
    messageContent = (
      <>
        <span>
          <strong className="fw-semibold">
            Thanks for confirming you&apos;ve paid! We&apos;re just waiting for
            you to upload your proof of payment.
          </strong>
          <div>
            <Button
              disabled={proof_uploaded}
              color="atlantis-primary"
              className="mt-2"
              size="sm"
              onClick={onProofUpload}
            >
              Upload Proof
            </Button>
          </div>
        </span>
      </>
    );
  } else if (message.type == "online-wallet-qr-code") {
    messageContent = (
      <span className="message-online-wallet-qr-code">
        <strong className="fw-semibold d-block">PAYMENT DETAILS</strong>
        <div className="chat-qr-code my-1">
          <QRCode size={150} data={message.text.qr_code.wallet_url} />
        </div>
        <FlexBox sx={{ alignItems: "center" }}>
          <a
            href={message.text.qr_code.wallet_url}
            target="_blank"
            rel="noreferrer"
          >
            {message.text.qr_code.wallet_url}
          </a>
          <Box ml={1}>
            <CopyButton text={message.text.qr_code.wallet_url} />
          </Box>
        </FlexBox>
      </span>
    );
  } else if (message.type == "mobile_wallet_info") {
    messageContent = (
      <>
        <strong className="text-center d-block w-100 mb-2">
          {message.text.payment_method_name}
        </strong>
        <FlexBox className="d-flex">
          <Small
            fontSize={15}
            width={"125px"}
            className="w-125px text-gray-600"
          >
            Full Name
          </Small>
          <Small fontSize={15} className="fw-semibold">
            {message.text.full_name}
          </Small>
          <CopyButton text={message.text.full_name} />
        </FlexBox>
        <FlexBox className="d-flex">
          <Small
            fontSize={15}
            width={"125px"}
            className="w-125px text-gray-600"
          >
            Phone
          </Small>
          <Small fontSize={15} className="fw-semibold">
            {formatPhoneNumberIntl(`+${message.text.phone}`)}
          </Small>
          <CopyButton text={formatPhoneNumberIntl(`+${message.text.phone}`)} />
        </FlexBox>
      </>
    );
  } else {
    messageContent = (
      <span
        dangerouslySetInnerHTML={{
          __html: message?.text ? message?.text?.replace(/\n/g, "<br />") : "",
        }}
      ></span>
    );
  }

  const isSuccessNotice = (message) => {
    return ["released_completed"].indexOf(message.type) > -1;
  };

  const isErrorNotice = (message) => {
    return ["trade_cancelled"].indexOf(message.type) > -1;
  };

  const isWarningNotice = () => {
    return [].indexOf(message.type) > -1;
  };

  const isNotice = (message) => {
    return ["marked_paid"].indexOf(message.type) > -1; //["trade_cancelled_user_chat", "trade_cancelled_admin"]
  };

  const getMessageBGColor = () => {
    if (isSuccessNotice(message)) {
      return "success.light";
    } else if (isErrorNotice(message)) {
      return "error.light";
    } else if (isWarningNotice(message)) {
      return "warning.light";
    } else if (!message.author) {
      return "primary.light";
    } else {
      switch (message.released_completed) {
        case "released_completed":
          return "success.light";
        default:
          return is_sent ? "#b7e6fc" : "primary.light";
      }
    }
  };

  const AutomatedMessageType = {
    greeting: "Greeting",
    closing: "Closing",
    message: "Message",
  };

  const is_notice =
    isSuccessNotice(message) ||
    isErrorNotice(message) ||
    isWarningNotice(message);

  return (
    <>
      {/* <Scrollbar
        style={{
          maxHeight: 562,
        }}
      > */}
      <Stack spacing={4} px={0} py={0}>
        <Box
          maxWidth={{
            md: is_notice ? "100%" : "80%",
            sm: is_notice ? "100%" : "80%",
            xs: is_notice ? "100%" : "80%",
          }}
          alignSelf={is_sent ? "end" : "start"}
          mb={2}
        >
          {is_sent && message.author && (
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <FlexBox pt={1} mb={1} justifyContent="end" alignItems="center">
                <>
                  <Box
                    mr={1}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <PaxfulProfilePic
                      size={30}
                      account={is_sent ? seller : buyer}
                      buyer={!is_sent}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: -7,
                        bottom: 0,
                        left: 30,
                        right: 0,
                      }}
                    >
                      <Platform size={20} platform={seller.platform} />
                    </Box>
                  </Box>

                  <Small mr={1}>{message.author}</Small>
                  <FlexBox
                    sx={{
                      justifyContent: is_sent ? "end" : "start",
                      cursor: "pointer",
                    }}
                  >
                    <Tiny fontSize={10} fontWeight={500}>
                      {message.timestamp && (
                        <MessageTimestamp timestamp={message.timestamp} />
                      )}
                    </Tiny>
                  </FlexBox>
                  <Small sx={{ marginLeft: "4px" }}>
                    {!is_webhook_msg && (
                      <span className="delivery-status ms-1">
                        {delivered === null && (
                          <>
                            <i className="fa-solid fa-circle-check text-muted fs-8"></i>
                          </>
                        )}
                        {delivered === true && (
                          <>
                            <i
                              className="fa-solid fa-circle-check  fs-8"
                              style={{ color: "#27CE88" }}
                            ></i>
                          </>
                        )}
                        {delivered === false && (
                          <>
                            <i className="fa-solid fa-circle-xmark text-danger fs-8"></i>
                          </>
                        )}
                      </span>
                    )}
                  </Small>
                </>
              </FlexBox>
            </Box>
          )}
          {!is_sent && message.author && (
            <Box
              sx={{
                textAlign: "left",
              }}
            >
              <FlexBox pt={1} mb={1} justifyContent="start" alignItems="center">
                <Box
                  mr={1}
                  sx={{
                    position: "relative",
                  }}
                >
                  <PaxfulProfilePic
                    size={30}
                    account={is_sent ? seller : buyer}
                    buyer={!is_sent}
                  />
                  <Box
                    // sx={{
                    //   minWidth: messageContent.length > 1 ? "480px" : "",
                    //   display: messageContent.length > 3 ? "flex" : "block",
                    // }}
                    sx={{
                      position: "absolute",
                      top: -7,
                      bottom: 0,
                      left: 30,
                      right: 0,
                    }}
                  >
                    <Platform size={20} platform={seller.platform} />
                  </Box>
                </Box>

                <Small mr={0.5}>{message.author}</Small>
                {message.author && !is_sent && (
                  <>
                    <Span mr={0.5}>
                      <CountryFlag
                        code={
                          isCountryCodeExists(
                            trade.partner_location.detected_location.iso
                          )
                            ? trade.partner_location.detected_location.iso
                            : trade.partner_location?.ip_location?.iso
                        }
                        width={"16px"}
                      />
                    </Span>
                  </>
                )}
                <FlexBox
                  sx={{
                    justifyContent: is_sent ? "end" : "start",
                    cursor: "pointer",
                  }}
                >
                  <Tiny fontSize={10} fontWeight={500}>
                    {message.timestamp && (
                      <MessageTimestamp timestamp={message.timestamp} />
                    )}
                  </Tiny>
                </FlexBox>
              </FlexBox>
            </Box>
          )}
          {!(message.author || message.type == "moderator_message") && (
            <FlexBox
              sx={{
                justifyContent: is_sent ? "end" : "start",
                cursor: "pointer",
              }}
            >
              <Tiny fontSize={10} fontWeight={500}>
                {message.timestamp && (
                  <MessageTimestamp timestamp={message.timestamp} />
                )}
              </Tiny>
            </FlexBox>
          )}
          {/* sender message or reciever message */}
          <FlexBox
            alignItems="center"
            mb={1}
            gap={1.5}
            justifyContent={is_sent ? "end" : "start"}
          >
            <Box
              sx={{
                position: "relative",
              }}
              textAlign={is_sent ? "end" : "start"}
            >
              {is_automated && (
                <Small fontSize={10} fontWeight={"bold"} color={"primary.main"}>
                  {message.type == "trade_attach_uploaded"
                    ? "Image"
                    : AutomatedMessageType[automated_type]}{" "}
                  automated by Venva
                </Small>
              )}
              {doc_type && (
                <>
                  {doc_type == "RECEIPT" && (
                    <Paragraph
                      fontSize={10}
                      fontWeight={"bold"}
                      color={"primary.main"}
                    >
                      Receipt
                    </Paragraph>
                  )}
                  {doc_type == "GIFT_CARD" && (
                    <Paragraph
                      fontSize={10}
                      fontWeight={"bold"}
                      color={"primary.main"}
                    >
                      Gift Card{" "}
                    </Paragraph>
                  )}
                  {doc_type == "GIFT_CARD_FRONT" && (
                    <Paragraph
                      fontSize={10}
                      fontWeight={"bold"}
                      color={"primary.main"}
                    >
                      Front of Gift Card{" "}
                    </Paragraph>
                  )}
                  {doc_type == "GIFT_CARD_BACK" && (
                    <Paragraph
                      fontSize={10}
                      fontWeight={"bold"}
                      color={"primary.main"}
                    >
                      Back of Gift Card{" "}
                    </Paragraph>
                  )}
                  {doc_type == "GOVT_ID" && (
                    <Paragraph
                      fontSize={10}
                      fontWeight={"bold"}
                      color={"primary.main"}
                    >
                      ID Proof{" "}
                    </Paragraph>
                  )}
                </>
              )}
              {/* message Content */}
              <Box
                className={`text-start${
                  message.type == "trade_attach_uploaded" ? " has-images" : ""
                }`}
                sx={{
                  padding: 2,
                  borderRadius: "8px",
                  backgroundColor: getMessageBGColor(),
                  border: `1px solid ${
                    is_sent || doc_type ? "#47b9f4" : "white"
                  }`,
                }}
              >
                <Box
                  sx={{
                    width: messageContent.length > 3 ? "480px" : "100%",
                    display: messageContent.length > 3 ? "flex" : "block",
                  }}
                  // fontWeight={500}
                  fontSize={"13px"}
                  lineHeight={1.7}
                  textAlign={"start"}
                  className={
                    messageContent.length > 3
                      ? "upload-images-wrap"
                      : messageContent.length === 3
                      ? "three-images"
                      : "two-image"
                  }
                  color={
                    settings.theme === "light" ? "text.secondary" : "black"
                  }
                  // is_sent ? "text.secondary" : "text.secondary"
                >
                  {messageContent}
                </Box>
              </Box>
            </Box>
            {/* copy button */}
            {!is_sent && message.type == "msg" && (
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  copyTextToClipboard(message.text);
                }}
              >
                <span className="svg-icon svg-icon-gray-600 svg-icon-4 ms-2">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      Moment
                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            )}
          </FlexBox>
          {paid_by && message.type == "marked_paid" && offer_type == "buy" && (
            <FlexBox sx={{ justifyContent: "start" }}>
              {paid_by.role === "va" ? (
                <>
                  <ATooltip
                    content={
                      <VATooltip
                        user={paid_by}
                        // hireRequestId={hireRequestId}
                        // isVa={profile.role === "va"}
                      />
                    }
                    className="trade-paid-by"
                  >
                    <Small
                      color={"text.secondary"}
                      fontSize={10}
                      className="is-va cursor-default"
                      style={{ cursor: "pointer" }}
                    >
                      Paid By {paid_by.username}
                    </Small>
                  </ATooltip>
                </>
              ) : (
                //isAdmin(sent_by.role) &&
                <ATooltip
                  content={
                    <UserInfo
                      user={paid_by}
                      showOnline={true}
                      is_live={false}
                    />
                  }
                >
                  <Small
                    color={"text.secondary"}
                    fontSize={10}
                    className="is-va cursor-default"
                    style={{ cursor: "pointer" }}
                  >
                    Paid by {paid_by.username}
                  </Small>
                </ATooltip>
              )}
            </FlexBox>
          )}
          {/* Send */}
          {sent_by && (
            <>
              {sent_by.role === "va" ? (
                <FlexBox sx={{ justifyContent: "end" }}>
                  <ATooltip
                    content={
                      <VATooltip user={sent_by} hireRequestId={hireRequestId} />
                    }
                  >
                    <Small
                      color={"text.secondary"}
                      fontSize={10}
                      className="is-va cursor-default"
                      style={{ cursor: "pointer" }}
                    >
                      Sent by {sent_by.username}
                    </Small>
                  </ATooltip>
                </FlexBox>
              ) : (
                <FlexBox sx={{ justifyContent: "end" }}>
                  <ATooltip
                    content={
                      <UserInfo
                        user={sent_by}
                        // showOnline={true}
                        is_live={false}
                      />
                    }
                  >
                    <Typography
                      color={"text.secondary"}
                      fontSize={10}
                      className="is-va cursor-default"
                      style={{ cursor: "pointer" }}
                    >
                      Sent by {sent_by.username}
                    </Typography>
                  </ATooltip>
                </FlexBox>
              )}
            </>
          )}
          {is_sent && !sent_by && (
            <>
              <FlexBox sx={{ justifyContent: "end" }}>
                <Typography
                  color={"text.secondary"}
                  fontSize={10}
                  className="is-va cursor-default"
                >
                  Sent on {platforms[account.platform].title}
                </Typography>
              </FlexBox>
            </>
          )}
        </Box>
      </Stack>
      {/* </Scrollbar> */}

      <div
        className={`d-flex justify-content-${is_sent ? "end" : "start"} mb-4`}
      >
        <div
          className={`d-flex flex-column align-items-${
            is_sent ? "end" : "start"
          }${
            isSuccessNotice(message) ||
            isErrorNotice(message) ||
            isWarningNotice(message)
              ? " w-100"
              : ""
          }`}
        >
          {message.author && (
            <div className="d-flex align-items-center mb-2">
              <div className="symbol symbol-35px symbol-circle">
                {/* <PaxfulProfilePic
                  account={is_sent ? seller : buyer}
                  buyer={!is_sent}
                /> */}
              </div>
              <div className="ms-3">
                <a
                  href="#"
                  className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                >
                  {/* {} */}
                </a>
                {/* <span className="text-muted fs-7 ms-1 mb-1 ms-2">
                 
                  {!is_webhook_msg && (
                    <span className="delivery-status ms-1">
                      {delivered === null && (
                        <>
                          <i className="fa-solid fa-circle-check text-muted fs-8"></i>
                        </>
                      )}
                      {delivered === true && (
                        <>
                          <i className="fa-solid fa-circle-check text-success fs-8"></i>
                        </>
                      )}
                      {delivered === false && (
                        <>
                          <i className="fa-solid fa-circle-xmark text-danger fs-8"></i>
                        </>
                      )}
                    </span>
                  )}
                </span> */}
              </div>
            </div>
          )}

          <div
            style={{
              position: "relative",
            }}
            className={`message-text p-4 fs-6 rounded text-dark fw-regular ${
              isSuccessNotice(message) ||
              isErrorNotice(message) ||
              isWarningNotice(message) ||
              isNotice(message)
                ? "w-100"
                : "mw-lg-400px"
            } text-start${
              message.type == "trade_attach_uploaded" ? " has-images" : ""
            }`}
            data-kt-element="message-text"
          >
            {/* {messageContent} */}

            {/* {!is_sent && message.type == "msg" && (
              <a
                className="cursor-pointer"
                onClick={() => {
                  copyTextToClipboard(message.text);
                }}
              >
                <span className="svg-icon svg-icon-gray-600 svg-icon-4 ms-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      Moment
                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

function Chat({ trade, featuredMessages, disabled }) {
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const downXl = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { setImages, openImage } = useContext(ImageGalleryContext);
  const { settings, saveSettings } = useContext(SettingsContext);
  const [stats, setStats] = useState(null);
  const getVendorDetails = async (account_id, buyer_id) => {
    const resp = await TradeServices.getVendorDetails(account_id, buyer_id);
    if (resp.status == true) {
      setStats(resp.data);
    }
  };

  const [chatMessage, setChatMessage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(-1);

  const [isUploading, setIsUploading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // const [scrollToBottom, setScrollToBotom] = useState(true);

  const [showEmojis, setShowEmojis] = useState(false);

  const [themMode, setThemMode] = useState("light");
  useEffect(() => {
    // if (trade && (trade.has_unread_chat || trade.has_unread_attachment)) {
    //   scrollChatToBottom();
    //   document.querySelector("#chat-message-input")?.focus();
    // }
    getVendorDetails(trade?.account._id, trade?.buyer_id);
    let _images = [];
    if (trade && trade.attachments) {
      trade.attachments.forEach(({ message, is_webhook_msg }) => {
        message.text.files.forEach(({ file_id, type, full_url }) => {
          if (type == 1) {
            let file_path = file_id
              ? `${API_BASE_URL}/files/s3/${file_id}`
              : `${SERVER_BASE_URL}/${full_url}`;

            // if (is_webhook_msg) {
            //   let url = new URL(full_url);
            //   let image_hash = url.pathname.substring(
            //     url.pathname.lastIndexOf("/") + 1
            //   );
            //   file_path = `${COMMON_API_BASE_URL}/uploads/paxful/attachments/${message.trade_hash}/${image_hash}.png`;
            // } else {
            //   file_path = `${COMMON_API_BASE_URL}/${full_url}`;
            // }
            if (is_webhook_msg) {
              //-
            }
            _images.push(file_path);
          }
        });
      });

      setImages(_images);
    }
    //----
  }, [trade]);

  const openDrawer = () => {
    setOpenRightDrawer(true);
  };

  const filesContent = (
    <Card
      sx={{
        maxHeight: 1000,
        height: "100%",
        pb: 1,
      }}
    >
      {/* <FlexBox justifyContent="center" gap={2} p={2}>
        <Common Icon={FileOutlined} amount={230} title="Files" />
        <Common Icon={Link} amount={230} title="Links" />
      </FlexBox> */}

      {/* <H5 p={2}>File Type</H5>
      <Scrollbar
        autoHide={false}
        style={{
          maxHeight: 435,
        }}
      >
        <Stack spacing={3} px={2}>
          <FileType />
        </Stack>
      </Scrollbar> */}
    </Card>
  );

  // const chatContainerRef = useRef();

  useEffect(() => {
    // if (chatContainerRef.current) {
    //   chatContainerRef.current.scrollIntoView({
    //     block: "end",
    //   });
    // }

    const ele = document.querySelector("#kt_chat_messenger_body");
    if (ele) {
      ele.scrollTo({
        top: ele.scrollHeight, // Scroll to the bottom
      });
    }
  }, [trade]);

  // useEffect(() => {
  //   window.scrollTo(0, 100);
  // }, []);

  // const simpleBarRef = useRef(null);

  // const scrollToBottom = () => {
  //   console.log("simpleBarRef.current", simpleBarRef.current);
  //   // const scrollElement = simpleBarRef.current.getScrollElement();
  //   // scrollElement.scrollTop = scrollElement.scrollHeight;
  // };

  // useEffect(() => {
  //   scrollToBottom(); // Scroll to bottom when component mounts or when messages change
  // }, []);

  return (
    <div>
      <Card
        sx={{
          maxHeight: 1000,
        }}
      >
        <Box p={2}>
          <FlexBox alignItems="center">
            {/* <PaxfulProfilePic size={35} account={trade?.buyer} /> */}
            {trade?.buyer && (
              <ProfileImageWithAccount
                pfSize={35}
                account={trade?.buyer}
                buyer
                platform={trade.account.platform}
                platformSize={22}
              />
            )}
            <Box ml={1.5}>
              <FlexBox>
                <a
                  style={{
                    marginLeft: "3px",
                    color: "black",
                  }}
                  href={
                    `https://${trade.account.platform}.com/user/` +
                    trade.buyer.username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Paragraph
                    fontSize={15}
                    color={settings.theme == "dark" ? "white" : "black"}
                  >
                    {trade.buyer.username}
                  </Paragraph>
                </a>

                <FlagAndPlatform
                  CountryName={
                    trade.partner_location.detected_location.localized_name
                  }
                  country={
                    isCountryCodeExists(
                      trade.partner_location.detected_location.iso
                    )
                      ? trade.partner_location.detected_location.iso
                      : trade.partner_location?.ip_location?.iso
                  }
                  // platform={trade.account.platform}
                />
              </FlexBox>
              <FlexBox
                sx={{
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <Chip
                  size="small"
                  icon={<ThumbUpIcon color="success" />}
                  label={trade.buyer.feedback_positive}
                />
                <Chip
                  sx={{
                    marginLeft: "5px",
                  }}
                  size="small"
                  icon={<ThumbDownIcon color="error" />}
                  label={trade.buyer.feedback_negative}
                />
                {/* <FlexBox onClick={openDrawer}>
                  {" "}
                  <Paragraph ml={1} fontSize={11} color="primary.main">
                    Partner Details
                  </Paragraph>
                  <InfoIcon
                    sx={{
                      marginLeft: "2px",
                    }}
                    fontSize={"xs"}
                    color="primary"
                  />
                </FlexBox> */}
              </FlexBox>
            </Box>
          </FlexBox>
        </Box>

        <Divider />
        <Grid container spacing={1}>
          <Grid item xl={8} md={8} xs={12}>
            <Box position="relative">
              <Scrollbar
                id="myElement"
                // innerRef={simpleBarRef}
                scrollToBottom={true}
                style={{
                  height: 800,
                }}
              >
                <Stack spacing={4} px={3} py={2}>
                  <div
                    id="kt_chat_messenger_body"
                    className="py-8 px-10"
                    // ref={chatContainerRef}
                    style={{
                      overflowY: "auto",
                    }}
                  >
                    {trade &&
                      trade.chat &&
                      trade.chat.map(
                        (
                          {
                            message,
                            is_webhook_msg,
                            delivered,
                            is_automated,
                            automated_type,
                            doc_type,
                            sent_by_va,
                            sent_by,
                          },
                          index
                        ) => (
                          <Message
                            trade={trade}
                            account={trade.account}
                            is_webhook_msg={is_webhook_msg}
                            delivered={delivered}
                            trade_id={trade._id}
                            key={index}
                            seller={trade.account}
                            buyer={trade.buyer}
                            message={message}
                            onImageSelect={openImage}
                            // shareBankAccount={handleBankAccountShare}
                            bank_accounts={trade.trade.bank_accounts}
                            amount={trade.trade.fiat_amount_requested}
                            bank_account_shared={trade.bank_account_shared}
                            bank_account_shared_by={
                              trade.bank_account_shared_by
                            }
                            offer_type={trade.trade.offer_type}
                            // onProofUpload={handleProofUpload}
                            proof_uploaded={trade.proof_uploaded}
                            proof_uploaded_by={trade.proof_uploaded_by}
                            payment_method_slug={
                              trade.trade.payment_method_slug
                            }
                            disabled={true}
                            is_automated={is_automated}
                            automated_type={automated_type}
                            doc_type={doc_type}
                            hireRequestId={trade.hireRequestId}
                            sent_by_va={sent_by_va}
                            sent_by={sent_by}
                            paid_by={trade.paidBy}
                          />
                        )
                      )}
                  </div>
                </Stack>
              </Scrollbar>
            </Box>

            <Divider />

            <FeaturedMessagesList
              onSelect={() => {}}
              trade={trade}
              profile={trade.user}
              account={trade.account}
              show={true}
            />
            <Divider />

            <Box>
              <FlexBetween padding={2} gap={2}>
                <InputBase
                  disabled
                  fullWidth
                  multiline
                  placeholder="Write a message..."
                  startAdornment={
                    <FlexBox mr={1.5} gap={1.5}>
                      <StyledIconButton>
                        <AttachmentDiagonal
                          sx={{
                            color: "text.disabled",
                          }}
                        />
                      </StyledIconButton>
                      <StyledIconButton>
                        <Image
                          sx={{
                            color: "text.disabled",
                          }}
                        />
                      </StyledIconButton>
                    </FlexBox>
                  }
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />

                <StyledIconButton>
                  <Send
                    sx={{
                      color: "primary.main",
                    }}
                  />
                </StyledIconButton>
              </FlexBetween>

              <Drawer
                anchor="right"
                open={openRightDrawer}
                onClose={() => setOpenRightDrawer(false)}
              >
                <Box width={330}>
                  <PartnerDetail trade={trade} />
                </Box>
              </Drawer>

              <Box>
                {trade && (
                  <Attachments trade={trade} onImageSelect={openImage} />
                )}{" "}
              </Box>
            </Box>
          </Grid>
          <Grid item xl={4} md={4} xs={12}>
            <Box
              className="sdsdsdsds"
              sx={{ borderLeft: 1, borderColor: "divider", height: "100%" }}
            >
              <PastTradeSidebar trade={trade} stats={stats} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Chat;

export const VATooltip = ({ user, hireRequestId }) => {
  return (
    <FlexBox sx={{ alignItems: "start" }}>
      <UserSymbol
        user={user}
        size={25}
        showOnline={true}
        isBottomZero={0}
        isVa={true}
      />
      <Box ml={1} className="ms-2">
        <FlexBox
          sx={{
            alignItems: "center",
          }}
        >
          <Small fontSize={13} className="mb-1 text-gray-800 tooltip-user-name">
            {user.username ? (
              user.username
            ) : (
              <>
                {user.first_name} {user.last_name}
              </>
            )}
          </Small>
          {user.country && (
            <Small ml={1} className="ms-2">
              <CountryFlag
                code={user.country}
                width="18px"
                showToolTip={false}
              />
            </Small>
          )}
        </FlexBox>

        {user.timezone && (
          <>
            <FlexBox mt={1} mb={0} className="d-flex mt-1 mb-1">
              <Span mr={1}>
                <BadgeMui color="primary.main">ASSISTANT</BadgeMui>
              </Span>

              <Timezone
                className={"timezone-tooltip-badge"}
                timezone={user.timezone}
                showTooltip={false}
                id={`member-${user._id}`}
              />
            </FlexBox>
            {/* <Box mb={1}>
              <BadgeMui color="success.main">Completed</BadgeMui>
            </Box> */}
          </>
        )}

        {/* <VASchedule requestId={hireRequestId} /> */}
      </Box>
    </FlexBox>
  );
};

export function MessageTimestamp({ timestamp }) {
  let date = new Date(timestamp * 1000);

  return (
    <>
      <ATooltip placement={"top"} content={<DateTime date={date} />}>
        <FromNow date={date} />
      </ATooltip>
    </>
  );
}
