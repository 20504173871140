import React, { useContext } from "react";

import { FlagAndPlatform } from "./PartnerDetail";
import { Small, Span } from "components/Typography";
import { Box, Chip } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import CryptoBalance from "components/shared/CryptoBalance";
import CryptoImg from "components/shared/CryptoImg";
import FormattedBalance from "components/shared/FormattedBalance";
import OfferType from "components/shared/OfferType";
import { formatCurrency, normalizeCrypto } from "utils/currencies";
import ATooltip from "components/shared/ATooltip";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { ChatIcon } from "components/shared/ChatIcon";
import { AttachmentIcon } from "components/shared/AttachmentIcon";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { UserContext } from "page-sections/user-list/ViewUser";
//import ExternalLink from "components/ExternalLink";
import platforms from "data/platforms";
import Platform from "components/shared/Platform";
import { getSecurityType } from "./TradeDetail";
import { ShoppingCart } from "@mui/icons-material";
import TradeTag from "./TradeTag";

export const TradeInfo = ({ title, children, isLast, className }) => {
  return (
    <Box
      mb={1}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className={`trade-details-info d-flex flex-stack${
        !isLast ? "  mb-2" : ""
      }${className ? ` ${className}` : ""}`}
    >
      <Span fontSize={13} className="text-gray-800">
        {title}
      </Span>
      <Span fontSize={13} fontWeight={500} className="fw-semibold">
        {children}
      </Span>
    </Box>
  );
};

function ShowTradeDetails({ trade, isPast = false }) {
  const { userdata } = useContext(UserContext);
  // console.log("trade.account", trade);
  const totalAttachment = trade.attachments.reduce((total, attachment) => {
    return total + (attachment?.message?.text?.files?.length || 0);
  }, 0);

  return (
    <Box
      sx={{
        border: "1px solid lightgrey",
        padding: "5px",
        marginBottom: "5px",
        borderRadius: "10px",
      }}
      className="p-4 border mb-6 card card-body"
    >
      <div className="mb-3">
        {/* <TradeVendor hyperLink={true} trade={trade} /> */}
        <Box mb={2} className="mb-5">
          <FlexBox
            sx={{ alignItems: "center" }}
            className="d-flex align-items-center"
          >
            {/* <ProfileImageWithAccount
              account={trade.buyer}
              buyer
              platform={trade.account.platform}
              platformSize={18}
              pfSize={40}
            /> */}
            <Box
              sx={{
                position: "relative",
              }}
            >
              <PaxfulProfilePic
                className={"me-1"}
                size={35}
                account={trade.account}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: -7,
                  bottom: 0,
                  left: 20,
                  right: 0,
                }}
              >
                <Platform platform={trade.account.platform} size={20} />
              </Box>
            </Box>
            {/* <PaxfulProfilePic account={trade.account} size={35} /> */}
            <Box
              sx={{
                marginLeft: "2px",
              }}
            >
              <FlexBox>
                <a
                  style={{
                    marginLeft: "3px",
                    // color: "black",
                  }}
                  href={
                    `https://${trade.account.platform}.com/user/` +
                    trade.account.paxful_username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Span
                    fontSize={12}
                    fontWeight={600}
                    my={0.5}
                    // color={settings.theme == "dark" ? "white" : "black"}
                  >
                    {trade.account.paxful_username}
                  </Span>
                </a>
                <FlagAndPlatform
                  CountryName={
                    trade.vendor_location.detected_location.localized_name
                  }
                  country={trade.vendor_location.detected_location.iso}
                  // platform={trade.account.platform}
                />
              </FlexBox>
              <div className="d-flex ms-4 align-items-center"></div>
              {/* <FeedbackBadge type="positive" value={2} /> */}
              <Chip
                size="small"
                icon={<ThumbUpIcon color="success" />}
                label={trade.account?.paxful_profile?.feedback_positive}
              />
              <Chip
                sx={{
                  marginLeft: "5px",
                }}
                size="small"
                icon={<ThumbDownIcon color="error" />}
                label={trade.account?.paxful_profile?.feedback_negative}
              />
            </Box>
          </FlexBox>
        </Box>
      </div>

      <TradeTag trade={trade} showNoResult />

      <TradeInfo className={"td-payment-method"} title={"Payment Method"}>
        <span>{trade.trade.payment_method_name}</span>
      </TradeInfo>

      <TradeInfo title={"Trade Amount"}>
        <FormattedBalance
          amount={trade.trade.fiat_amount_requested}
          code={trade.trade.fiat_currency_code}
          offer_type={trade.trade.offer_type}
          status={trade.status}
          user={userdata}
        />
      </TradeInfo>

      <TradeInfo title={"Crypto Amount"}>
        <CryptoImg width={15} crypto={trade.trade.crypto_currency_code} />
        <CryptoBalance
          fs={6}
          code={trade.trade.crypto_currency_code}
          amount={trade.trade.crypto_amount_total}
          offer_type={trade.trade.offer_type}
          status={trade.status}
          user={userdata}
        />
      </TradeInfo>

      <TradeInfo title={"Crypto Fiat Value"}>
        {formatCurrency(trade.fiat_value, trade.trade.fiat_currency_code)}{" "}
        {trade.trade.fiat_currency_code}
      </TradeInfo>

      <TradeInfo title={"Trade Hash"}>
        {/* <ExternalLinkConfirmation
          id={`trade-hash`}
          target="_blank"
          className="text-ag-link"
          href={`https://${trade.account.platform}.com/trade/${trade.trade.trade_hash}`}
        > */}
        <a
          href={`https://${trade.account.platform}.com/trade/${trade.trade.trade_hash}`}
          target="_blank"
          rel={"noreferrer"}
        >
          {trade.trade.trade_hash}
        </a>

        {/* {trade.paxful_trade_hash} */}
        {/* </ExternalLinkConfirmation> */}
      </TradeInfo>

      <TradeInfo title={"Offer Hash"}>
        <a
          href={`https://${trade.account.platform}.com/offer/${trade.trade.offer_hash}`}
          target="_blank"
          rel={"noreferrer"}
        >
          {trade.trade.offer_hash}
        </a>
        {/* <ExternalLinkConfirmation
          id={`offer-hash`}
          target="_blank"
          className="text-ag-link"
          href={`https://${trade.account.platform}.com/offer/${trade.trade.offer_hash}`}
        > */}
        {/* </ExternalLinkConfirmation> */}
      </TradeInfo>

      <TradeInfo title={"Offer Type"}>
        {trade.trade.offer_type && <OfferType value={trade.trade.offer_type} />}
      </TradeInfo>

      <TradeInfo title={"Margin"}>
        <span>{trade.trade.margin}%</span>
      </TradeInfo>
      <TradeInfo title={` ${platforms[trade.account.platform].title} Fee`}>
        {formatCurrency(trade.fee, trade.trade.fiat_currency_code)}{" "}
        {trade.trade.fiat_currency_code}
        {/* {normalizeCrypto(
          trade.trade.fee_crypto_amount,
          trade.trade.crypto_currency_code
        )}{" "}
        {trade.trade.crypto_currency_code}) */}
      </TradeInfo>

      {trade.estimated_profit && (
        <TradeInfo title={"Profit"}>
          <Small color="success.main">
            {formatCurrency(
              trade.estimated_profit,
              trade.trade.fiat_currency_code
            )}
          </Small>
        </TradeInfo>
      )}

      <TradeInfo title={"Trade Statistics"} isLast={true}>
        <FlexBox
          mr={1}
          clasName={`d-flex align-items-center${
            isPast ? " force-primary" : ""
          }`}
        >
          <Span>
            <ATooltip
              placement={"bottom-end"}
              content={`${
                trade.chat.length - trade.attachments.length
              } Total Messages`}
            >
              <ChatIcon
                index={0}
                active={false}
                // blink={
                //   trade.unread_messages > 0 &&
                //   trade.unread_messages + trade.unread_files > 2
                // }
                blink={false}
                count={trade.chat.length - trade.attachments.length}
                disabled={!isPast}
              />
            </ATooltip>
          </Span>
          <Span
            sx={{
              marginLeft: "15px",
            }}
          >
            <ATooltip
              placement={"bottom-end"}
              content={`${totalAttachment} Total Attachments`}
            >
              <AttachmentIcon
                index={0}
                active={trade.has_unread_attachment}
                // blink={
                //   trade.unread_files > 0 &&
                //   trade.unread_messages + trade.unread_files > 2
                // }
                blink={false}
                count={totalAttachment}
                noMargin
                disabled={!isPast}
              />
            </ATooltip>
          </Span>
          {trade.otp_verified && (
            <ATooltip id={`kyc-completed`} content={`KYC completed`}>
              <Box ml={1}>
                <a>
                  <span className="svg-icon svg-icon-muted svg-icon-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </a>
              </Box>
            </ATooltip>
          )}
          {trade.trade_security && trade.trade_security != "none" && (
            <ATooltip
              content={`Security Type: ${getSecurityType(
                trade.trade_security
              )}`}
            >
              <span className=" me-1">
                <i
                  className="fa-solid fa-lock"
                  style={{
                    color: "#2499EF",
                    fontSize: "18px",
                    marginTop: "2px",
                    marginLeft: "10px",
                  }}
                ></i>
              </span>
            </ATooltip>
          )}
          {trade.giftCards &&
            trade.giftCards != null &&
            trade.giftCards?.length > 0 && (
              <ATooltip
                // offset={offset}
                content={"Inventory added"}
                // placement={securityPlacement}
              >
                <Box ml={1}>
                  <ShoppingCart ml={1} color="primary" />
                </Box>

                {/* <KeenIcon icon={"handcart"} color={"primary"} /> */}
              </ATooltip>
            )}
        </FlexBox>
      </TradeInfo>
    </Box>
  );
}

export default ShowTradeDetails;
