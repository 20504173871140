import { ContentCopy, Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import TradeStatus from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { FlagAndPlatform } from "page-sections/profiles/profile/Trades/PartnerDetail";
import UserSymbol from "components/shared/UserSymbol";
import OfferType from "components/shared/OfferType";
import { formatCurrency } from "utils/currencies";
import CryptoBalance from "components/shared/CryptoBalance";
import FormattedBalance from "components/shared/FormattedBalance";
import CustomPopover from "components/CustomPopover";
import { VATooltip } from "page-sections/profiles/profile/Trades/Chat";
import ATooltip from "components/shared/ATooltip";
import UserInfo from "components/shared/UserInfo";
import platforms from "data/platforms";
import { isEmptyArray } from "formik";
import TradeStatIcons from "components/shared/TradeStatIcons";
import notification from "helpers/notifications";
import { displayPartsToString } from "typescript";
import CreatedBy from "components/shared/CreatedBy";
import TimeElapsedTimer from "components/TimeElapsedTimer";
import { isCountryCodeExists } from "utils/utils";
import TradeTag from "page-sections/profiles/profile/Trades/TradeTag";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
const TradeHistoryColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "15px" }}>DATE</Box>,
    accessor: "completedAt",
    Cell: ({ row }) => {
      const { completedAt, startedAt, status, createdAt, sortTimeStamp } =
        row.original;
      let date = completedAt ? completedAt : startedAt;
      console.log("completedAt", completedAt);
      console.log("completedAt ===============");

      return (
        <Box
          sx={{
            minWidth: "148px",
          }}
        >
          <Paragraph fontSize={13} color="text.secondary">
            <FormattedDateTime date={sortTimeStamp} />
          </Paragraph>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={date} />
          </Small>
        </Box>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "15px" }}> VENDOR</Box>,
    accessor: "user",
    Cell: ({ value }) => {
      const user = value[0];
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <span>
              <UserSymbol
                user={user}
                size={25}
                showOnline={true}
                isBottomZero={0}
              />
            </span>
            <Box
              sx={{
                marginLeft: "10px !important",
              }}
            >
              <Link to={`/dashboard/users/${user._id}`}>{user.username}</Link>
            </Box>
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "15px" }}> ACCOUNT</Box>,
    accessor: "account.paxful_username",
    Cell: ({ row }) => {
      const { paxful_username, platform } = row.original.account;
      return <>{<PaxfulAccount item={row.original.account} size={25} />}</>;
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}> TRADE HASH</Box>,
    accessor: "trade.trade_hash",
    Cell: ({ row, onClick }) => {
      const {
        user,
        account,
        trade,
        status,
        trade_chat,
        unread_messages,
        unread_files,
        otp_verified,
        trade_security,
        trade_attachments,
        giftCards,
      } = row.original;
      const { _id } = user[0];

      const totalAttachmentCount = trade_attachments[0]?.messages?.reduce(
        (total, message) => {
          return total + (message?.message?.text?.files?.length || 0);
        },
        0
      );

      console.log("trade_attachments", trade_attachments);
      return (
        <Box sx={{ minWidth: 150 }}>
          {/* <Paragraph
            sx={{
              cursor: "pointer",
            }}
            color="primary.main"
            onClick={() => onClick(trade.trade_hash)}
          >
            {trade.trade_hash}
          </Paragraph>{" "} */}

          {/* {status == "paid" ? (
            <Paragraph fontSize={12} fontWeight={"bold"}>
              {trade.trade_hash}
            </Paragraph>
          ) : ( */}
          <Link to={`/dashboard/users/${_id}?trade=${trade.trade_hash}`}>
            {trade.trade_hash}
            <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
              <svg
                width="20"
                height="20"
                viewBox="0 -5 22 22"
                fill="none"
                // xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="16.9497"
                  y="8.46448"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(135 16.9497 8.46448)"
                  fill="currentColor"
                ></rect>
                <path
                  d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </Link>
          {/* )} */}
          <FlexBox mt={1} minWidth={110} sx={{ cursor: "default" }}>
            <TradeStatIcons
              chatCount={
                trade_chat[0] && trade_chat[0]?.messages.length > 0
                  ? trade_chat[0]?.messages.length -
                    trade_attachments[0]?.messages.length
                  : 0
              }
              chatDisabled={Number(unread_messages) < 3}
              chatBlink={Number(unread_messages) > 3}
              attachmentCount={totalAttachmentCount}
              attachmentDisabled={Number(unread_files) < 3}
              attachmentBlink={Number(unread_files) > 3}
              otp_verified={otp_verified}
              trade_security={trade_security}
              giftCards={giftCards}
            />
          </FlexBox>
        </Box>
      );
    },
  },

  {
    Header: () => (
      <Box sx={{ marginLeft: "12px", width: "135px" }}> PAYMENT METHOD</Box>
    ),
    accessor: "trades",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return (
        <Box>
          <Paragraph mr={2} fontSize={12}>
            {" "}
            {trade.payment_method_name}
          </Paragraph>
          <TradeTag
            className={"trade-history-tags mt-2"}
            trade={row.original}
          />

          {/* <OfferType value={trade.offer_type} /> */}
        </Box>
      );
    },
  },
  {
    Header: "",
    accessor: "fgf",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return (
        <FlexBox>
          <OfferType value={trade.offer_type} />
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}>PARTNERS </Box>,
    accessor: "buyer",
    Cell: ({ row }) => {
      const { buyer, partner_location, account } = row.original;

      return (
        <FlexBox justifyContent="start" alignItems="center">
          <PaxfulProfilePic size={25} account={buyer[0].profile} buyer />
          {/* {buyer[0] && <BuyerProfilePic size={30} buyer={buyer[0]} />} */}

          {buyer[0].username && (
            <>
              <FlexBox fontSize={12} fontWeight={500}>
                <a
                  style={{
                    marginLeft: "3px",
                  }}
                  href={
                    `https://${account.platform}.com/user/` + buyer[0].username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {buyer[0].username}
                </a>
                <a>
                  {/* <ArrowOutward
                    sx={{
                      fontSize: 14,
                      marginLeft: "1px",
                    }}
                  /> */}
                </a>
              </FlexBox>
              {partner_location && (
                <FlagAndPlatform
                  CountryName={
                    partner_location.detected_location.localized_name
                  }
                  country={
                    isCountryCodeExists(partner_location.detected_location.iso)
                      ? partner_location.detected_location.iso
                      : partner_location?.ip_location?.iso
                  }
                  platform={account.platform}
                />
              )}
            </>
          )}
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>FEE </Box>,
    accessor: "fbhdbf",
    Cell: ({ row }) => {
      let item = row.original;
      return (
        <>
          {item?.atlantis_fee_details?.atlantis_fee ? (
            <Paragraph color="text.secondary" fontSize={15} fontWeight="600">
              {formatCurrency(item.atlantis_fee_details.atlantis_fee)}{" "}
            </Paragraph>
          ) : (
            <Paragraph color="text.secondary" fontWeight="600" fontSize={15}>
              $0
            </Paragraph>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}> AMOUNT </Box>,
    accessor: "amount",
    Cell: ({ row }) => {
      let item = row.original;
      let user = row.original.user[0];
      return (
        <>
          <FlexBox
            sx={{
              minHeight: "70px",
              minWidth: "140px",
              flexDirection: "column",
              justifyContent: "center",
              cursor: "default",
            }}
          >
            <Small fontWeight={"bold"}>
              <FormattedBalance
                offer_type={item.trade.offer_type}
                code={item.trade.fiat_currency_code}
                amount={item.trade.fiat_amount_requested}
                status={item.status}
                user={user}
              />
            </Small>
            <span className="cursor-default text-gray-700">
              <CryptoBalance
                offer_type={item.trade.offer_type}
                code={item.trade.crypto_currency_code}
                amount={item.trade.crypto_amount_total}
                status={item.status}
                user={user}
              />
            </span>
            {item.status !== "cancelled" && (
              <div className="fs-7 cursor-default">
                Profit:{" "}
                <Small color="success.main">
                  {formatCurrency(
                    item.estimated_profit,
                    item.trade.fiat_currency_code
                  )}
                </Small>
              </div>
            )}
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ row }) => {
      const {
        releasedBy,
        status,
        vaActivity,
        hireRequestId,
        is_synced,
        account,
        trade,
        buyer,
        user,
        cancelledBy,
      } = row.original;
      // console.log("RoWW", row);
      return (
        <Box
          sx={{
            minWidth: "160px",
            textAlign: "right !important",
            cursor: "default",
          }}
        >
          {<TradeStatus _trade={row.original} />}

          {cancelledBy?.length > 0 && status == "cancelled" && (
            <ReleasedBy
              title={"Cancelled By"}
              user={cancelledBy[0]}
              role={cancelledBy[0].role}
              hireRequestId={hireRequestId}
            />
          )}

          {releasedBy?.length > 0 && status == "released" && (
            <>
              <Box mt={1} sx={{ minWidth: "150px" }}>
                <Small
                  sx={{
                    display: "block",
                  }}
                  className="text-muted"
                >
                  Released by
                </Small>
                <CreatedBy user={releasedBy[0]} otherUser />
                {/* <ATooltip content={<UserInfo user={user[0]} is_live={false} />}>
                  <Paragraph
                    fontWeight="bold"
                    fontSize="8"
                    color="primary.main"
                    className="fs-7 fw-bolder text-primary cursor-pointer"
                  >
                    {" "}
                    {user[0].first_name}
                  </Paragraph>
                </ATooltip> */}
                {/* {releasedBy[0].role === "va" ? (
                  <ATooltip
                    content={
                      <UserInfo
                        user={releasedBy[0]}
                        hireRequestId={hireRequestId}
                      />
                    }
                  >
                    <Paragraph
                      color={"primary.main"}
                      fontSize={11}
                      className="fs-7 fw-bolder text-primary cursor-pointer"
                    >
                      {" "}
                      {releasedBy[0].first_name}
                    </Paragraph>
                  </ATooltip>
                ) : (
                  <ATooltip content={<UserInfo user={releasedBy[0]} />}>
                    <Paragraph
                      color={"primary.main"}
                      fontSize={11}
                      className="fs-7 fw-bolder text-primary cursor-pointer"
                    >
                      {" "}
                      {releasedBy[0].first_name}
                    </Paragraph>
                  </ATooltip>
                )} */}
              </Box>
            </>
          )}
          {row.original.status === "paid" && (
            <div>
              <ATooltip content="Time Elapsed">
                <Paragraph
                  mt={1}
                  color="primary.main"
                  fontSize={12}
                  fontWeight={600}
                  sx={{
                    cursor: "default",
                  }}
                >
                  <TimeElapsedTimer
                    start={row.original.startedAt}
                    id={row.original._id}
                  />
                </Paragraph>
              </ATooltip>
            </div>
          )}

          {!releasedBy?.length > 0 && status == "released" && is_synced && (
            <Box>
              {" "}
              <Paragraph color={"text.secondary"} fontSize={11} mt={1}>
                Synced from {platforms[account.platform].title}
              </Paragraph>{" "}
            </Box>
          )}

          {!releasedBy?.length > 0 &&
            status == "released" &&
            !is_synced &&
            trade.offer_type == "sell" && (
              <Box className="fs-8 release d-block">
                <Small color="text.secondary" fontSize={11}>
                  Released on {platforms[account.platform].title}
                </Small>
              </Box>
            )}
          {!releasedBy?.length > 0 &&
            status == "released" &&
            !is_synced &&
            trade.offer_type == "buy" && (
              <FlexBox mt={1}>
                Released by{" "}
                <ATooltip
                  content={
                    <FlexBox sx={{ alignItems: "center" }}>
                      <Paragraph color={"text.secondary"} fontSize={11}>
                        {buyer[0].username}
                      </Paragraph>
                      <Small ml={1}>
                        <Platform platform={account.platform} size={15} />
                      </Small>
                    </FlexBox>
                  }
                >
                  <Box ml={1} style={{ position: "relative" }}>
                    <PaxfulProfilePic
                      account={buyer[0].profile}
                      size={15}
                      buyer
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-5px",
                        right: "-10px",
                      }}
                    >
                      <Platform
                        size={15}
                        platform={account.platform}
                        className="h-15px platform-icon"
                      />
                    </Box>
                  </Box>
                </ATooltip>
              </FlexBox>
            )}
        </Box>
      );
    },
  },
];
export default TradeHistoryColumnShape;

export const ReleasedBy = ({ title, role, user, hireRequestId }) => {
  // const { activeVAs } = useSelector(({ hire }) => hire);

  // it's va portal schedule
  // const { schedules } = useSelector(({ user }) => user);

  // const schedule = activeVAs.find(
  //   ({ request_id }) => request_id == hireRequestId
  // );

  // const isOnline =
  //   schedules?.request_id === hireRequestId
  //     ? schedules?.is_active
  //     : schedule?.is_active;

  return (
    <Box mt={1} className="status-wrapper d-inline-flex mt-2">
      <Paragraph m={0} fontSize={13} fontWeight={500}>
        {title}
      </Paragraph>

      <ATooltip
        content={
          <div className="text-start">
            <Paragraph color="text.secondary" className="text-gray-600 mb-2">
              {title}:
            </Paragraph>
            {role === "va" ? (
              <>
                <VATooltip user={user} hireRequestId={hireRequestId} />
              </>
            ) : (
              <UserInfo user={user} is_live={false} />
            )}
          </div>
        }
      >
        <FlexBox sx={{ alignItems: "center" }}>
          <UserSymbol
            user={user}
            size={25}
            showOnline={true}
            isVa={role === "va" ? true : false}
            isBottomZero={0}
          />
          <Paragraph
            ml={1}
            fontSize={12}
            sx={{ color: "gray" }}
            className={`text-gray-600 cursor-default ms-1`}
          >
            <span style={{ cursor: "default" }}>
              {user.username ? (
                user.username
              ) : (
                <>
                  {user.first_name} {user.last_name}
                </>
              )}
            </span>
          </Paragraph>
        </FlexBox>
      </ATooltip>
    </Box>
  );
};
