import { Box } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import { Tiny } from "components/Typography";
import React, { useState } from "react";
import { lightTheme } from "../../../constants";
import { FromNow } from "components/shared/FormattedDate";
import { SERVER_BASE_URL } from "config";
import { FslightGalleryForSingleImage } from "page-sections/profiles/profile/Trades/FsligthGallery";
import ATooltip from "components/shared/ATooltip";
import DateTime from "components/shared/DateTime";

const IncomingMsg = ({ item }) => {
  const [toggler, setToggler] = useState(false);

  return (
    <Box
      maxWidth={{
        md: "60%",
        sm: "70%",
        xs: "80%",
      }}
    >
      <FlexBox mb={1} gap={1.5}>
        <AppAvatar src="/static/avatar/055-bodybuilder.svg" />
        <Box
          sx={{
            padding: 2,
            borderRadius: "8px",
            backgroundColor: (theme) =>
              lightTheme(theme) ? "primary.100" : "divider",
          }}
        >
          <Tiny
            display="block"
            fontWeight={500}
            lineHeight={1.7}
            color="text.secondary"
          >
            {item.message}
          </Tiny>

          {item?.file && (
            <>
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setToggler(!toggler)}
              >
                <img
                  src={`${SERVER_BASE_URL}/${item?.file}`}
                  width={"40px"}
                  height={"40px"}
                />
              </a>
              <FslightGalleryForSingleImage
                image={`${SERVER_BASE_URL}/${item?.file}`}
                toggler={toggler}
              />
            </>
          )}
        </Box>
      </FlexBox>
      <Tiny fontSize={10} fontWeight={500} textAlign="LEFT">
        <ATooltip
          placement={"top"}
          content={<DateTime date={item.createdAt} />}
        >
          <span style={{ cursor: "pointer" }}>
            <FromNow date={item.createdAt} />
          </span>
        </ATooltip>
      </Tiny>
    </Box>
  );
};

export default IncomingMsg;
