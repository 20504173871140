import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  styled,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import columnShape from "page-sections/user-list/columnShape";
import HeadingArea from "page-sections/user-list/heading-area";
import UserService from "../services/UserService";
import { connect } from "react-redux";
import DeleteDialog from "components/shared/DeleteDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { arrayIsEmpty, copyTextToClipboard } from "utils/utils";
import NoResultFound from "components/shared/NoResultFound";
import { H4, H5, H6, Paragraph, Small, Span } from "components/Typography";
import { LocalFireDepartment } from "@mui/icons-material";
import SearchInput from "components/input-fields/SearchInput";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import { UserContextData } from "App";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import IconWrapper from "components/IconWrapper";
import GroupSenior from "icons/GroupSenior";
import Log from "icons/duotone/Log";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JSONPretty from "react-json-pretty";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Webhooklog from "services/Webhooklog";
import { FormattedDateTime, FromNow } from "components/shared/FormattedDate";
import ErrorLogService from "services/ErrorLogService";
import { SettingsContext } from "contexts/settingsContext";

const Wrapper = styled(Box)(() => ({
  // display: "flex",
  // flexWrap: "wrap",
  // alignItems: "center",
  // justifyContent: "space-between",
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 13,
  // fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});

const ErrorLog = () => {
  const [data, setData] = useState(null);
  const [logFile, setLogFile] = useState(null);
  const [logContent, setLogContent] = useState(null);
  const { settings } = useContext(SettingsContext);

  const [option, setOption] = useState("active");
  const [tabValue, setTabValue] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = async (row) => {
    setLogFile(row);
    let resp = await ErrorLogService.readErrorLog(row);
    setLogContent(resp);
  };

  // const handleTabChange = (event, newValue) => {
  //   const filters = { ...filter };
  //   filters.limit = 50;
  //   filters.page = 1;
  //   filters.tabValue = newValue;
  //   setSelectedRow(null);
  //   setFilter(filters);
  // };
  // const handleChangePage = (event, newPage) => {
  //   const filters = { ...filter };
  //   filters.page = newPage + 1; // Adjust for 1-based index
  //   setFilter(filters);
  // };

  // const handleLimitChange = (event) => {
  //   const newLimit = parseInt(event.target.value, 10);
  //   const filters = { ...filter };
  //   filters.limit = newLimit;
  //   filters.page = 1;
  //   setFilter(filters);
  // };

  const ErrorLog = async () => {
    const resp = await ErrorLogService.getErrorLog();
    setData(resp);
  };

  useEffect(() => {
    ErrorLog();
  }, []);

  console.log("settings", settings);

  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
          height: "85vh",
        }}
      >
        <Box px={3}>
          <Wrapper gap={1}>
            <FlexBetween flexWrap="wrap" gap={1} mb={2}>
              <FlexBox alignItems="center">
                <IconWrapper>
                  <Log
                    sx={{
                      color: "primary.main",
                    }}
                  />
                </IconWrapper>
                <H5>{`Error Log`}</H5>
              </FlexBox>
            </FlexBetween>
          </Wrapper>
        </Box>

        <Box py={4}>
          <Grid container>
            <Grid
              xl={4}
              md={4}
              xs={12}
              sx={{
                // borderLeft: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <TableContainer px={5} sx={{ height: "90vh" }}>
                <Box>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Small ml={1} color="text.secondary" fontWeight={600}>
                            FILE
                          </Small>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <Divider sx={{ borderWidth: 1 }} />
                    <TableBody>
                      {data && arrayIsEmpty(data) ? (
                        <TableCell colSpan={2} align="center">
                          <NoResultFound />
                        </TableCell>
                      ) : (
                        data &&
                        data.map((row) => (
                          <>
                            <TableRow
                              key={row._id}
                              onClick={() => handleRowClick(row)}
                              sx={{
                                cursor: "pointer",
                                backgroundColor:
                                  selectedRow && selectedRow._id === row._id
                                    ? "primary.300"
                                    : "inherit",
                                borderRadius: "25px !important",
                              }}
                            >
                              <TableCell
                                align="left"
                                sx={{
                                  paddingLeft: "5px !important",
                                  paddingY: "12px !important",
                                }}
                              >
                                <Paragraph fontSize={13} color="text.secondary">
                                  {row}
                                </Paragraph>
                              </TableCell>
                            </TableRow>
                            <Divider />
                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </TableContainer>
            </Grid>

            <Grid
              xl={8}
              md={8}
              xs={12}
              sx={{
                borderLeft: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Paper elevation={3} sx={{ flexGrow: 1, height: 800, p: 2 }}>
                {/*  {selectedFile || "Select a file"} */}
                <H4 color={"text.secondary"}>
                  Log File: {logFile ? logFile : "Select a file"}
                </H4>
                <Scrollbar
                  // scrollToBottom={true}
                  style={{
                    height: "750px",
                    background: `${
                      settings.theme == "dark" ? "#000" : "aliceblue"
                    }`,
                    color: "#0f0",
                    padding: "10px",
                  }}
                >
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {logContent?.content || "No log file selected"}
                  </pre>
                </Scrollbar>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default ErrorLog;
